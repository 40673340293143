import "./officeViewer.css";

export const OfficeViewer = ({ url }) => {
  if (!url) {
    return <div>Loading...</div>;
  }
  var encodedUrl = encodeURIComponent(url);
  const source = `https://view.officeapps.live.com/op/embed.aspx?src=${encodedUrl}`
  return (
    <div className="office-viewer">
      <iframe
        src={source+"#toolbar=0&embedded=true"}
        width="100%"
        height="700px"
        frameborder="0"
      >
        This is an embedded{" "}
        <a target="_blank" href="http://office.com">
          Microsoft Office
        </a>{" "}
        document, powered by{" "}
        <a target="_blank" href="http://office.com/webapps">
          Office Online
        </a>
        .
      </iframe>
    </div>
  );
};

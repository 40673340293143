import "./signin.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dashboard } from "../dashboard";

import { InputField } from "../ui/inputField";

// import { FcGoogle } from "react-icons/fc";
// import { AiFillApple } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import { ButtonComp } from "../ui/button";
import { DividerLine } from "../ui/DividerLine";

import { signInwithEmailAndPAssword, signInasGuest } from "../../apis";

import { checkIfSignedIn } from "../utils/checkIfSignedIn";

const SignInComp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errmsg, setErrMesg] = useState("");
  const [rememberMe, SetRemeberMe] = useState(true);

  const navigator = useNavigate();

  let returnid = localStorage.getItem("id");
  let returnLoc = localStorage.getItem("id_loc");
  let fromLoc = localStorage.getItem("prevLocation");

  const handleForgotPassword = () => {
    navigator("/forgot");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (e.key === "Enter") {
      return signIn();
    }
    signIn();
  };

  const signIn = () => {
    if (email === "") {
      setErrMesg("Email Cannot be empty!");
      return;
    }
    if (password === "") {
      setErrMesg("Password Cannot be empty!");
      return;
    }
    signInwithEmailAndPAssword(email, password, rememberMe)
      .then((res) => {
        // fix this respone
        if (res.data.response.result.message === "Login successful") {
          localStorage.setItem(
            "accessToken",
            res.data.response.result.accessToken
          );
          if (res.data.response.result.user.isProfileUpdated) {
            if (returnid !== null && returnLoc !== null && fromLoc !== null) {
              navigator(`${returnLoc}?id=${returnid}&location=${fromLoc}`);
            } else {
              navigator(`/search`);
            }
          } else {
            navigator("/update");
          }
        }
        if (res.data.response.result.message === "Password not set") {
          setErrMesg("Your password was not set, Please check your email.");
        }
        if (res.data.response.result.message === "mail not confrimed") {
          setErrMesg("Your Email was not confirmed, Please check your email.");
        }
      })
      .catch((error) => {
        setErrMesg(error.response.data.response.params.errmsg);
      });
  };

  const handleSigninAsGuest = () => {
    signInasGuest().then((res) => {
      if (res.data.response.result.message === "Guest Login successful") {
        localStorage.setItem(
          "accessToken",
          res.data.response.result.accessToken
        );
        localStorage.setItem("isGuest", true);
        if (returnid !== null && returnLoc !== null && fromLoc !== null) {
          navigator(`${returnLoc}?id=${returnid}&location=${fromLoc}`);
        } else {
          navigator(`/search`);
        }
      }
    });
  };

  useEffect(() => {
    if (checkIfSignedIn()) {
      navigator("/search");
    }
    localStorage.setItem("prevLocation", "SignInPage");
  }, []);

  return (
    <Dashboard>
      <div className="dashboard-component">
        <div className="pb-4">
          <h2 className="form-title">Sign in</h2>
          <small className="fs-6">
            New User?
            <a
              className="px-1 signIn-link mouse-cursor aLink_color--purple"
              onClick={() => navigator("/signup")}
            >
              Sign Up
            </a>
          </small>
        </div>
        <form
          onSubmit={(event) => handleSubmit(event)}
          autoComplete="off"
          className="dashboard-component-form"
        >
          <InputField
            isRequired={true}
            title="Email"
            value={email}
            type="email"
            onChange={setEmail}
            errorMess={errmsg}
            customErrMessClass="text-danger"
          />
          {/* {errmsg !== "" ? <ShowErrMess errmsg={errmsg} /> : ""} */}
          <InputField
            isRequired={true}
            title="Password"
            value={password}
            type="password"
            onChange={setPassword}
          />
          <span className="forgot-me__link">
            <a
              className="signIn-link mouse-cursor aLink_color--purple pb-3"
              onClick={() => handleForgotPassword()}
            >
              Forgot Password
            </a>
          </span>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={rememberMe}
              onChange={() => SetRemeberMe((prev) => !prev)}
              id="flexCheckDefault"
            />
            <label className="form-check-label">Remember me</label>
          </div>

          <ButtonComp
            title="Sign In"
            onClick={(event) => handleSubmit(event)}
            customStyle="btn-custom my-3 w-100 search-comp_pills"
            disabled={email === "" || password === ""}
          />
        </form>
        <DividerLine />
        <div className="d-flex flex-column signIn__btn--options">
          {/* <ButtonComp
            title="Sign in with Google"
            customStyle="btn-white my-1 border"
            icon={<FcGoogle className="icon-size mx-2" />}
            disabled={true}
          />
          <ButtonComp
            title="Sign in with Apple"
            customStyle="btn-white my-1 border"
            icon={<AiFillApple className="icon-size mx-2" />}
            disabled={true}
          /> */}
          <ButtonComp
            title="Sign in as a Guest"
            customStyle="btn-white my-1 border"
            icon={<FaUserAlt className="icon-size mx-2" />}
            onClick={handleSigninAsGuest}
          />
        </div>
      </div>
    </Dashboard>
  );
};

export { SignInComp };

import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import { ButtonComp } from "../ui/button";
import { InputField } from "../ui/inputField";

import { Dashboard } from "../dashboard/dashboard";

import { verifyToken, setPaswword } from "../../apis";
import { checkIfSignedIn } from "../utils/checkIfSignedIn";

import "./activation.css";
const { joiPassword } = require("joi-password");
const joi = require("joi");

const ActivationComp = () => {
  const navigator = useNavigate();

  const [params] = useSearchParams();

  const [loading, setLoading] = useState(true);

  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [error, setError] = useState("");
  const [passErr, showPassErr] = useState(false);
  const [passStr, setPassStr] = useState(false);

  const [mess, setMess] = useState("Loading...");

  const handleClick = (event) => {
    event.preventDefault();
    setPaswword(params.get("code"), password)
      .then((res) => {
        if (res.data.response.result.isPasswordSet) {
          localStorage.setItem(
            "accessToken",
            res.data.response.result.accessToken
          );
          if (res.data.response.result.isProfileUpdated) {
            navigator("/search");
          } else {
            navigator("/update");
          }
        } else setMess(res.data.response.result.message);
      })
      .catch((err) => setError(err));
  };

  const schema = (input) =>
    joi
      .object({
        password: joiPassword
          .string()
          .minOfSpecialCharacters(1)
          .minOfUppercase(1)
          .minOfNumeric(1)
          .messages({
            "password.minOfUppercase":
              "Must contain at least {#min} uppercase character",
            "password.minOfSpecialCharacters":
              "Must contain at least {#min} special character",
            "password.minOfNumeric":
              "Must contain at least {#min} numeric character",
          }),
      })
      .validate(input, { abortEarly: false });

  useEffect(() => {
    verifyToken(params.get("code"))
      .then((res) => {
        if (res.data.response.result.isVerified) {
          setLoading(false);
        }
        if (res.data.response.result.message === "Invalid Token") {
          setMess("Invalid Token");
        }
      })
      .catch((err) => setError(err));
  }, []);

  useEffect(() => {
    let err = schema({ password: password });
    if (password.length === 0) {
      setError("");
      setPassStr(false);
    } else if (password.length < 8) {
      setError("Must be at least 8 characters");
      setPassStr(false);
    } else if (err.error === undefined) {
      setError("");
      setPassStr(true);
    } else {
      setError(err.error.message);
      setPassStr(false);
    }
  }, [password]);

  useEffect(() => {
    if (cPassword === "") {
      showPassErr(false);
      return;
    }
    if (cPassword !== password) {
      showPassErr(true);
    } else showPassErr(false);
  }, [cPassword]);

  return (
    <Dashboard>
      {loading ? (
        <p>{mess}</p>
      ) : (
        <ActivatePassword
          password={password}
          setPassword={setPassword}
          cPassword={cPassword}
          setCPassword={setCPassword}
          error={error}
          passErr={passErr}
          handleClick={handleClick}
          passStr={passStr}
        />
      )}
    </Dashboard>
  );
};

export { ActivationComp };

const ActivatePassword = ({
  password,
  setPassword,
  cPassword,
  setCPassword,
  error,
  passErr,
  handleClick,
  passStr,
}) => {
  return (
    <div className="activation-component">
      <div className="pb-4">
        <h2 className="form-title">Set passsword</h2>
      </div>
      <InputField
        title="Set password"
        type="password"
        value={password}
        onChange={setPassword}
      />
      <InputField
        title="New password"
        type="password"
        value={cPassword}
        onChange={setCPassword}
      />

      <div className="d-flex flex-column">
        {error.split(".").map((x) => {
          return (
            <span key={x} className="text-danger">
              {x}
            </span>
          );
        })}
        {passErr ? (
          <span className="text-danger">Passwords do not match</span>
        ) : (
          ""
        )}
      </div>

      <ButtonComp
        title="Continue"
        customStyle="btn-custom w-100"
        onClick={handleClick}
        disabled={!passStr || password !== cPassword ? true : false}
      ></ButtonComp>
    </div>
  );
};

import "./button.css"

const ButtonComp = ({onClick, title, customStyle, icon, disabled}) => {
    let customClass = `btn mouse-cursor btn-lg ${customStyle}`
  return (
    <button
      type="button"
      className={customClass}
      onClick={(event) => onClick(event)}
      disabled={disabled}
    >
      {icon}      
      {title}
    </button>
  );
};

export { ButtonComp };

// import { useState } from "react";
// import { Document, Page, pdfjs } from "react-pdf";

// import { useEffect } from "react";
// import { AiOutlineZoomIn, AiOutlineZoomOut } from "react-icons/ai";
// import { GrFormPreviousLink, GrFormNextLink } from "react-icons/gr";

// import "./pdfviewer.css";
// // import "./trpl2"

// import "react-pdf/dist/esm/Page/AnnotationLayer.css";
// // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import { useEffect } from "react";
import ViewSDKClient from "./ViewSDKClient.js";

const PdfModalViewer = ({ url, filename }) => {
  const loadPDF = () => {
    const viewSDKClient = new ViewSDKClient();
    viewSDKClient.ready().then(() => {
      viewSDKClient.previewFile(
        "pdf-div",
        {
          showAnnotationTools: false,
          showLeftHandPanel: false,
          showPageControls: true,
          showDownloadPDF: false,
          showPrintPDF: false,
        },
        url,
        " "
      );
    });
  };

  useEffect(() => {
    loadPDF();
  }, []);

  useEffect(() => {
    loadPDF();
  }, [url]);

  return (
    <div className="office-viewer">
      <div id="pdf-div" className="full-window-div"></div>
    </div>
  );
};

export { PdfModalViewer };

// useEffect(() => {
//   // let frame = document.getElementById("iframe").contentWindow.document.getElementById('download')
//   // console.log(frame)
// }, []);

// const [numPages, setNumPages] = useState(1);
// const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page

// const [zoom, setZoom] = useState(2);

// function onDocumentLoadSuccess({ numPages }) {
//   setNumPages(numPages);
//   setPageNumber(1);
// }

// function changePage(offset) {
//   setPageNumber((prevPageNumber) => prevPageNumber + offset);
// }

// function previousPage(event) {
//   event.preventDefault();
//   changePage(-1);
// }

// function nextPage(event) {
//   event.preventDefault();
//   changePage(1);
// }

// <div className="office-viewer">
//   <iframe src={"https://docs.google.com/viewer?embedded=true&url="+url} width="70%" height="700px" frameBorder="0">
//   <h1>Hello</h1>
//   </iframe>
// </div>

// <div className={style+" pdf-viewer_wrapper"}>
//   <div className="pdf-view_buttons mt-3">
//     <button
//       className="pdf-viewer--button"
//       type="button"
//       disabled={pageNumber <= 1}
//       onClick={previousPage}
//     >
//       <GrFormPreviousLink className="fs-3 m-2 p-0" />
//     </button>
//     <div className="ms-2 me-2">
//       Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
//     </div>

//     <button
//       className="pdf-viewer--button"
//       type="button"
//       disabled={pageNumber >= numPages}
//       onClick={nextPage}
//     >
//       <GrFormNextLink className="fs-3 m-2 p-0" />
//     </button>

//     <span onClick={() => setZoom(zoom - 1.2)}>
//       <AiOutlineZoomOut className="fs-3 m-2 p-0" />
//     </span>
//     <span onClick={() => setZoom(zoom + 1.2)}>
//       <AiOutlineZoomIn className="fs-3 m-2 p-0" />
//     </span>
//   </div>

//   <div className="viewer_custom-container">
//     <Document
//       file={url}
//       onLoadSuccess={onDocumentLoadSuccess}
//       onLoadError={console.error}
//       source={{
//         header: {
//           "Access-Control-Allow-Origin": "*",
//         },
//       }}
//       options={{
//         workerSrc: "/pdf.worker.js",
//         // cMapUrl: 'cmaps/',
//         // cMapPacked: true,
//       }}
//     >
//       <Page
//         className="pdf-viewer_canvas"
//         pageNumber={pageNumber}
//         scale={zoom}
//       />
//     </Document>
//   </div>
// </div>

import axios from "axios"
import { useEffect, useState } from "react"
import "./textViewer.css"

export const TextViewer = ({url}) => {
    const [text, setText] = useState('')

    useEffect(() => {
        axios.get(url).then(res => setText(res.data))
    })
    return (text)
}
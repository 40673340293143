import "./audioplayer.css";
import React from "react";

import ReactAudioPlayer from "react-audio-player";

export const AudioViewer = ({ url }) => {
  return (
    <div className="audio-player-wrapper">
      <ReactAudioPlayer src={url} title="mp3" controls />
    </div>
  );
};

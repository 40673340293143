import "./dashboard.css";

const Dashboard = ({ children }) => {
  return (
    <div className="body-container p-3">
      <div className="body-container__display">
        <div className="leftCol_div">
          <div className="leftCol_div-image1"></div>
          <div className="leftCol_div-image2"></div>
          <div className="d-flex text-white justify-content-center px-5 flex-column custom-height-100 dashboard-bg main-left-container">
            <b>
              <h1 className="dashboard_text">
                <div className="leftCol_fiv-yellow-cirle"></div>
                Learn.
              </h1>
              <h1 className="dashboard_text">Design. </h1>{" "}
              <h1 className="dashboard_text">Connect.</h1>
            </b>
          </div>
        </div>
        <div className="rightCol_div body-container__login">
          <div className="d-flex main-center">{children}</div>
        </div>
      </div>
    </div>
  );
};

export { Dashboard };

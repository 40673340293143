import "./contactus.css";
import { useState, useEffect } from "react";
import { contactUs, teleEvent } from "../../../apis";

export const ContactUs = ({ setModalShow, setModalTitle }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showErr, setShowErr] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateEmail(email)) {
      contactUs(name, email, message).then((res) => {
        setModalShow(true);
        setModalTitle("Thank you for writing to us. We'll get back to you shortly!");
        setName("");
        setEmail("");
        setMessage("");
        setShowErr(false);
        teleEvent(
          localStorage.getItem("accessToken"),
          "contactFormSubmit",
          "ContactPage"
        );
      });
    } else {
      setShowErr(true);
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  useEffect(() => {
    teleEvent(
      localStorage.getItem("accessToken"),
      "contact",
      localStorage.getItem("prevLocation")
    )
      .then(() => {
        localStorage.setItem("prevLocation", "ContactPage");
      })
      .catch(() => {
        localStorage.setItem("prevLocation", "ContactPage");
      });
  }, []);

  return (
    <div className="contactus-comp">
      <div className="seach-comp_background--wrapper contactus_page-background">
        <div className="seach-comp_background" />
      </div>
      <div className="contactus-comp_container">
        <div className="contactus-comp_container--header">Contact us</div>
        <div className="contactus-comp_container--header-text">
          We'd love to hear from you!
        </div>

        <div className="contactus-comp_form">
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div className="input-group mb-3 input-group-lg d-flex search-input">
              <span className="input-group-text search-comp_icon contactus-comp--imput">
                Name
              </span>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                className="form-control search-comp_input contactus-comp--imput"
                required
              />
            </div>
            {showErr && (
              <span className="text-danger">Enter a valid email</span>
            )}
            <div className="input-group mb-3 input-group-lg d-flex search-input">
              <span className="input-group-text search-comp_icon contactus-comp--imput">
                Email
              </span>
              <input
                type="text"
                className="form-control search-comp_input contactus-comp--imput "
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="input-group mb-3 input-group-lg d-flex search-input">
              <textarea
                type="text"
                className="form-control search-comp_input  contactus-comp--imput"
                required
                placeholder="How can we help you? "
                rows="5"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
            <div className="contactus-comp_button-wrapper">
              <button
                className="btn btn-custom contactus-comp--button"
                disabled={(name === "") | (email === "") | (message === "")}
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

import "./faq.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { contactUs, teleEvent } from "../../../apis";

export const Faq = ({ setModalShow, setModalTitle }) => {
  const navigator = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showErr, setShowErr] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateEmail(email)) {
      contactUs(name, email, message).then((res) => {
        setModalShow(true);
        setModalTitle("Thank you for writing to us. We'll get back to you shortly!");
        setName("");
        setEmail("");
        setMessage("");
        setShowErr(false);
        teleEvent(localStorage.getItem('accessToken'), "contactFormSubmit", "FaqPage")
      });
    } else {
      setShowErr(true);
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  useEffect(() => {
    teleEvent(
      localStorage.getItem("accessToken"),
      "faq",
      localStorage.getItem("prevLocation")
    )
      .then(() => {
        localStorage.setItem("prevLocation", "FaqPage");
      })
      .catch(() => {
        localStorage.setItem("prevLocation", "FaqPage");
      });
  }, []);

  return (
    <div className="faq-comp">
      <div className="faq-comp_wrapper">
        <div className="mb-2 faq-header_100">Societal Thinking FAQs</div>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="according-header">
              <b>What is Societal Thinking?</b>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                As the world becomes more connected, the ways of solving social
                problems also need to evolve. Leaders need to reimagine wicked
                problems and re-define how they can be solved by the key actors
                of the society including communities, markets, Government and
                civil society.
              </p>
              <p>
                Societal Thinking is a way to reimagine and realise{" "}
                <b>exponential social change</b>. This systemic approach helps
                change leaders to find new ways of solving social problems - by
                bringing together diverse people, restoring their agency and
                providing them with accessible infrastructure that allows them
                to solve their own problems.
              </p>
              <i>
                “ Exponential change ~ when every change induces more and rapid
                changes”
              </i>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <b>
                What are the key elements of Societal Thinking? Where can I
                learn them?
              </b>
            </Accordion.Header>
            <Accordion.Body>
              <p>The key elements of Societal Thinking constitute:</p>
              <ul>
                <li>
                  Core Values: They form the foundation for a societal mission
                  and pave the way for inducing exponential change by guiding
                  the change leaders and innovators in making design decisions
                  and deciding how to act.{" "}
                </li>
                <li>
                  Societal Model: It is the representation of the societal
                  mission and helps in reimagining ways in which critical
                  aspects of the problem at hand, such as scarcity, diversity
                  and scale are perceived and acted upon.{" "}
                </li>
                <li>
                  Design Principles: Various sets of design principles have been
                  abstracted from the experiences of diverse missions
                  implementing Societal Thinking in their domain and context.
                  These principles guide change leaders and innovators to design
                  for exponential change.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <b>What are the examples of Societal Thinking in action?</b>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                No matter what domain and in what context you are working in,
                some or the other elements of Societal Thinking can be
                applicable at different stages in your journey. From
                reimagination to implementation to scaling, various elements
                like the Core values, Societal Model, Design Principles. This
                Design Kit can aid in the implementation of Societal Thinking
                with various design modules that consist of questions for
                introspection, processes, examples and templates.
              </p>
              <p>
                Additionally, you can explore - How Societal Thinking is
                complementary or +1 to other forms of thinking, such as Design
                Thinking and Systems Thinking.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <b>
                How do I start practising Societal Thinking in my organisation?
              </b>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Once you have learnt about the key elements of Societal
                Thinking, a good starting point to practise it would be to
                conduct a Core Values AS-IS reflection activity within your
                organisation.
              </p>
              <p>
                This reflection activity is designed for you and your team to
                reflect upon the as-is alignment with the Societal Thinking Core
                Values. It can serve as a frame of reference for your
                organisation in identifying and prioritising key focus areas in
                order to create a way forward.
              </p>

              <p>
                <u>Tip:</u> Many organisations have found it valuable to repeat
                the reflection activity with the larger ecosystem, to co-create
                a shared vision and narrative of the approach.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4">
            <Accordion.Header>
              <b>
                Do I have to be a not-for-profit to practice Societal Thinking?
              </b>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                No, you don't necessarily have to be a not-for-profit to
                practise this approach.
              </p>
              <p>
                Societal Thinking can be, and is, employed by various actors
                from civil society, markets, governments and communities, in
                diverse domains, geographies and contexts to induce exponential
                social change.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="5">
            <Accordion.Header>
              <b>
                What does Societal Thinking mean for the first mile
                actors/beneficiaries?
              </b>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                At the core of this approach is the agency of the first mile
                actors or beneficiaries, along with the agency of other actors
                in the ecosystem. Everything we do, should be done in order to
                restore the agency of these actors. With a higher sense of
                agency, dignity and choice, the first mile actors are liberated
                from just being beneficiaries of social efforts into becoming a
                formidable force - a change agent - in their local community, in
                their local context.
              </p>
              <p>
                Thus, Societal Thinking enables various actors of the ecosystem
                to reimagine and realise exponential social change.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className=" mb-2 mt-2 faq-header_100">SPace FAQs</div>
        <Accordion>
          <Accordion.Item eventKey="6">
            <Accordion.Header>
              <b>What is SPace?</b>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                SPace is a platform for the Societal Thinking community to
                Learn, Design, and Connect with one another. It is designed to
                be open and free for everyone to use. On SPace, you can:
              </p>
              <ul>
                <li>
                  <b>Learn</b> about Societal Thinking and it's manifestations
                </li>
                <li>
                  Explore resources to <b>Design</b> your Societal Programs and
                  Platforms
                </li>
                <li>
                  Discover avenues to <b>Connect</b> with the Societal Thinking
                  community
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="7">
            <Accordion.Header>
              <b>What can I do on SPace?</b>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Learn: Explore the Societal Thinking methods and experiences of
                the community
              </p>
              <p>
                Design: Explore toolkits, examples and reusable assets of the
                community
              </p>
              <p>
                Connect: Discover opportunities to learn and share with the
                community events
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="8">
            <Accordion.Header>
              <b>What does the Societal Thinking community look like?</b>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                The Societal Thinking community on SPace is a vibrant mix of
                change leaders from diverse geographies, domains, and areas of
                expertise – Learners, Practitioners and Supporters – at
                different stages of their own journey of working towards
                generating exponential societal change.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="9">
            <Accordion.Header>
              <b>What happens to my data? Is it safe?</b>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                We respect your privacy and are committed to protecting it.
                Refer to our{" "}
                <a href="#" onClick={() => navigator("/PrivacyPolicy")}>
                  Data and Privacy Policy
                </a>{" "}
                to learn more about the ways in which the Platform leverages and
                protects your personal information and data.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="10">
            <Accordion.Header>
              <b>How can I publish my content on SPace?</b>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                We’ll be happy to host the resources and toolkits that are
                relevant to the idea of exponential social change and Societal
                Thinking.
              </p>
              <p>
                Please write to us at <a>space@societalplatform.org</a> with the
                details of the resources that you wish to contribute to SPace.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <div className="contactus-comp_container mb-5 mt-3">
          <div className="contactus-comp_container--header-text m-0">
            We'd love to hear from you!
          </div>

          <div className="contactus-comp_form">
            <form autoComplete="off" onSubmit={handleSubmit}>
              <div className="input-group mb-3 input-group-lg d-flex search-input">
                <span className="input-group-text search-comp_icon contactus-comp--imput">
                  Name
                </span>
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  className="form-control search-comp_input contactus-comp--imput"
                  required
                />
              </div>
              {showErr && (
                <span className="text-danger">Enter a valid email</span>
              )}
              <div className="input-group mb-3 input-group-lg d-flex search-input">
                <span className="input-group-text search-comp_icon contactus-comp--imput">
                  Email
                </span>
                <input
                  type="text"
                  className="form-control search-comp_input contactus-comp--imput "
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="input-group mb-3 input-group-lg d-flex search-input">
                <textarea
                  type="text"
                  className="form-control search-comp_input  contactus-comp--imput"
                  required
                  placeholder="How can we help you? "
                  rows="5"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              <div className="contactus-comp_button-wrapper">
                <button
                  className="btn btn-custom contactus-comp--button"
                  disabled={(name === "") | (email === "") | (message === "")}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

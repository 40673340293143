import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InputField } from "../ui/inputField";
import { ButtonComp } from "../ui/button";
import {
  updateUser,
  getUserDetails,
  getUploadUrl,
  uploadToGoogle,
  teleEvent,
} from "../../apis";
import "./update.css";
import { FaUserCircle } from "react-icons/fa";
import { AiFillCamera } from "react-icons/ai";
const countryList = require("country-list");

const UpdateComp = ({ setModalShow, setModalTitle }) => {
  const accessToken = localStorage.getItem("accessToken");
  const navigator = useNavigate();

  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [org, setOrg] = useState("");
  const [country, setCountry] = useState("");
  const [img, setImg] = useState("");
  const [role, setRole] = useState("");
  const [domain, setDomain] = useState("");
  const [domainList, setDomainList] = useState([]);
  const [expertise, setExpertise] = useState("");
  const [expertiseList, setExpertiseList] = useState([]);
  const [profileLink, setProfileLink] = useState("");
  const [emailConsent, setEmailConsent] = useState(true);
  const [imageUrl, setImageUrl] = useState();

  const getUploadUrlFromApi = (e) => {
    getUploadUrl(e.target.files[0].name).then((res) => {
      if (res.status === 200) {
        setImageUrl(res.data.fileName);
        base64Img(e.target.files[0]);
        uploadToGoogle(
          res.data.url,
          e.target.files[0],
          e.target.files[0].name.split(".").pop()
        );
      }
    });
  };

  const base64Img = (img) => {
    let reader = new FileReader();
    reader.readAsDataURL(img);
    reader.onloadend = () => {
      setImg(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const handleClick = (event) => {
    event.preventDefault();
    updateUser(
      fname,
      lname,
      org,
      country,
      role,
      domainList,
      expertiseList,
      profileLink,
      emailConsent,
      imageUrl
    )
      .then((res) => {
        if (
          (res.data.response.result.message = "Profile updated successfully.")
        ) {
          setModalTitle("Successfully updated your profile");
          setModalShow(true);
          navigator("/search");
        }
      })
      .catch((err) => {
        if (err.response.data.response.params.errmsg === "Invalid Token") {
          localStorage.clear();
          navigator("/");
        }
      });
  };

  useEffect(() => {
    getUserDetails(accessToken)
      .then((res) => {
        if (res.data.response.result.isGuest) {
          navigator("/search");
        }
        setFName(res.data.response.result.data.firstName);
        setLName(res.data.response.result.data.lastName);
        setOrg(res.data.response.result.data.organization);
        setCountry(res.data.response.result.data.country);
        setRole(res.data.response.result.data.role);
        setDomainList(res.data.response.result.data.domain);
        setExpertiseList(res.data.response.result.data.areaOfExpertise);
        setProfileLink(res.data.response.result.data.profileLink);
        setImg(res.data.response.result.data.imageUrl);
        setEmailConsent(res.data.response.result.data.newsLetterSubscribed);
      })
      .catch((err) => {
        if (err.response.data.response.params.errmsg === "Invalid Token") {
          localStorage.clear();
          navigator("/");
        }
      });
    teleEvent(
      localStorage.getItem("accessToken"),
      "profile",
      localStorage.getItem("prevLocation")
    ).then(() => {
      localStorage.setItem("prevLocation", "ProfilePage");
    });
  }, []);

  const handleDomainArr = (event) => {
    if (domain === "") return;
    if (domain.length < 1) return;
    if (event.key === "Enter") {
      setDomainList([...domainList, domain]);
      setDomain("");
    }
  };

  const handleDomainArrBlur = () => {
    if (domain === "") return;
    if (domain.length < 1) return;
    setDomainList([...domainList, domain]);
    setDomain("");
  };

  const handleExpertiseArrBlur = () => {
    if (expertise === "") return;
    if (expertise.length < 1) return;
    setExpertiseList([...expertiseList, expertise]);
    setExpertise("");
  };

  const handlExpertiseArr = (event) => {
    if (expertise.length < 1) return;
    if (expertise === "") return;
    if (event.key === "Enter") {
      setExpertiseList([...expertiseList, expertise]);
      setExpertise("");
    }
  };

  const handleDomainSlice = (i) => {
    const newdomainList = [...domainList];
    newdomainList.splice(i, 1);
    setDomainList(newdomainList);
  };

  const handleExpertiseSlice = (i) => {
    const newexpertiseList = [...expertiseList];
    newexpertiseList.splice(i, 1);
    setExpertiseList(newexpertiseList);
  };
  return (
    <div className="body-container p-3">
      <div className="body-container__display">
        <div className="update-comp-left_div">
          <div className="update-page__pfp">
            <div className="user-icon--large">
              <label for="files" className="pfp-container">
                {img?.length !== 0 ? (
                  <img
                    className="update-page__photo"
                    src={img}
                    height={"200px"}
                    width={"200px"}
                    alt="Profile Image"
                  ></img>
                ) : (
                  <FaUserCircle
                    size={"200px"}
                    className="update-page__svg"
                  ></FaUserCircle>
                )}
                <AiFillCamera className="update-page__svg-plus" />
              </label>

              <input
                id="files"
                className="profile-upload-icon"
                type="file"
                accept="image/*"
                onChange={getUploadUrlFromApi}
              ></input>
            </div>
            <div className="d-flex flex-row">
              <InputField
                title="First name"
                type="text"
                value={fname}
                onChange={setFName}
                customStyle="update-comp__custom-button fs-2 me-2 update-comp--name"
              />
              <InputField
                title="Last name"
                type="text"
                value={lname}
                onChange={setLName}
                customStyle="update-comp__custom-button fs-2 ms-2 update-comp--name "
              />
            </div>
          </div>

          <form onSubmit={handleClick} autoComplete="off">
            <div className="update-comp__form form-group py-3">
              <div className="update-comp__input">
                <InputField
                  title="Organization"
                  isRequired={true}
                  type="text"
                  value={org}
                  onChange={setOrg}
                  customStyle="update-comp__custom-button"
                />
              </div>

              <div className="update-comp__input">
                <div className="form-outline mb-4">
                  <label className="form-label">
                    Country
                    <span className="text-danger">
                      <sup>*</sup>
                    </span>
                  </label>
                  <input
                    className="form-control update-comp__custom-button"
                    value={country}
                    type="text"
                    onChange={(e) => setCountry(e.target.value)}
                    required
                    autoComplete="new-password"
                    maxLength="50"
                    list="country"
                  />
                  <datalist id="country" className="bg-white text-danger">
                    {countryList.getNames().map((x, i) => (
                      <option
                        key={i}
                        value={x}
                        className="bg-white text-danger"
                      />
                    ))}
                  </datalist>
                </div>
              </div>

              <div className="update-comp__input--no-margin">
                <InputField
                  title="Role"
                  isRequired={true}
                  type="text"
                  value={role}
                  onChange={setRole}
                  customStyle="update-comp__custom-button"
                />
              </div>
            </div>

            <div className="update-comp__input py-3">
              <InputField
                title="Domain / Area of work"
                bottomText="Press enter to add more"
                type="text"
                isRequired={true}
                value={domain}
                onChange={setDomain}
                onKeyDown={handleDomainArr}
                onBlur={() => handleDomainArrBlur()}
                customStyle="update-comp__custom-button"
              />
              <div>
                {domainList !== undefined &&
                  domainList.map((x, i) => (
                    <span
                      className="btn btn-custom me-2 mt-1 update-comp_pills"
                      key={i}
                    >
                      {x}
                      <span
                        className="mouse-cursor ms-2 update-comp_x"
                        onClick={() => handleDomainSlice(i)}
                      >
                        X
                      </span>
                    </span>
                  ))}
              </div>
            </div>

            <div className="update-comp__input py-3">
              <InputField
                type="text"
                title="Expertise"
                bottomText="Press enter to add more"

                value={expertise}
                onChange={setExpertise}
                customStyle="update-comp__custom-button"
                onKeyDown={handlExpertiseArr}
                onBlur={handleExpertiseArrBlur}
              />
              <div className="">
                {expertiseList !== undefined &&
                  expertiseList.map((x, i) => (
                    <span
                      className="btn btn-custom me-2  mt-1 update-comp_pills"
                      key={i}
                    >
                      {x}
                      <span
                        className="mouse-cursor ms-2"
                        onClick={() => handleExpertiseSlice(i)}
                      >
                        X
                      </span>
                    </span>
                  ))}
              </div>
            </div>

            <div className="py-3">
              <InputField
                title="Profile Link"
                type="text"
                value={profileLink}
                onChange={setProfileLink}
                customStyle="update-comp__custom-button"
              />
            </div>

            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                onChange={() => setEmailConsent(!emailConsent)}
                checked={emailConsent}
                id="flexCheckDefault"
              />
              <label className="form-check-label">
                I would like to receive updates from the Societal Thinking team.
              </label>
            </div>
            <div className="py-2"></div>

            <div className="py-3 d-flex justify-content-end">
              <ButtonComp
                title="Cancel"
                type="submit"
                onClick={() => navigator("/search")}
                customStyle="btn-custom m-2 cancel-button"
              />

              <ButtonComp
                title="Finish"
                type="submut"
                onClick={handleClick}
                customStyle="btn-custom m-2 finish-button"
                disabled={
                  org === "" ||
                  (domainList !== undefined && domainList.length === 0) ||
                  country === "" ||
                  fname === "" ||
                  role === ""
                    ? true
                    : false
                }
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export { UpdateComp };

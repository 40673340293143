import "./view.css";
import { useState, useEffect } from "react";

import { useNavigate, useSearchParams, useLocation } from "react-router-dom";

import { ViewController } from "../../ui/ModalViewer/viewController";

import { Accordion } from "react-bootstrap";

import { MdOutlineArrowBack } from "react-icons/md";
import { AiOutlineLike, AiFillLike } from "react-icons/ai";
import { FiExternalLink } from "react-icons/fi";
import {
  getBundleRes,
  getIndividualRes,
  downloadCounter,
  colLikeCounter,
} from "../../../apis";

import { TbDownload } from "react-icons/tb";

import moment from "moment";

import { saveAs } from 'file-saver';


export const CollResView = ({ setModalShow, setModalTitle }) => {
  const navigator = useNavigate();
  const [params] = useSearchParams();
  const [title, setTitle] = useState("Loading...");
  const [bundle, setBundle] = useState([]);

  const [activeUrl, setActiveUrl] = useState("");
  const [activeType, setActiveType] = useState("");
  const [activeId, setActiveId] = useState("");
  const [activeDownload, setActiveDownload] = useState("");
  const [activeTitle, setActiveTitle] = useState("");

  const [attachment, setAttachment] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [desc, setDesc] = useState([]);
  const [pubDate, setPubDate] = useState();
  const [pubBy, setPubBy] = useState();
  const [like, setLike] = useState(0);
  const [likeActive, setLikeActive] = useState();
  const [userType, setUserType] = useState("");
  const [windowWidth, setWindowWidth] = useState("");

  const handleLikeCllick = () => {
    if (userType === "Guest") {
      plsLoginIn("like");
      return;
    }
    if (likeActive) {
      setLikeActive(!likeActive);
      setLike((prev) => prev - 1);
      colLikeCounter(
        localStorage.getItem("accessToken"),
        params.get("id"),
        "CollViewPage"
      );
    } else {
      if (like === undefined) {
        setLike(1);
      } else {
        setLike((prev) => prev + 1);
      }
      setLikeActive(!likeActive);
      colLikeCounter(
        localStorage.getItem("accessToken"),
        params.get("id"),
        "CollViewPage"
      );
    }
  };

  const handleClick = (id) => {
    getIndividualRes(
      localStorage.getItem("accessToken"),
      id,
      params.get("location")
    )
      .then((res) => {
        setActiveUrl(res.data.response.result.preSingedUrl);
        setActiveType(res.data.response.result.docType);
        setActiveId(id);
        setActiveDownload(res.data.response.result.downloadUrl);
        setAttachment(res.data.response.result.data.attachment);
        setPubDate(res.data.response.result.data.publishedDate);
        setPubBy(res.data.response.result.data.publishedBy);
        // setLike(res.data.response.result.data.likes);
        setUserType(res.data.response.result.userType);
        setActiveTitle(res.data.response.result.data.docTitle);
      })
      .catch((err) => {
        if (err.response.data.response.params.errmsg === "Invalid Token") {
          localStorage.clear();
          navigator("/");
        }
      });
  };

  const handleDownload = async () => {
    const downloadResult = await fetch(setActiveDownload);
    const blob = await downloadResult.blob();
    saveAs(blob, `${activeTitle}${activeType}`);
    downloadCounter(
      localStorage.getItem("accessToken"),
      activeId,
      "CollViewPage"
    );
  };
  const plsLoginIn = (mess) => {
    setModalTitle(`Please sign in to ${mess}`);
    setModalShow(true);
  };

  useEffect(() => {
    getBundleRes(localStorage.getItem("accessToken"), params.get("id"))
      .then((res) => {
        setTitle(res.data.response.result.data.bundleTitle);
        setBundle(res.data.response.result.data.docIds);
        setKeywords(res.data.response.result.data.keywords);
        setDesc(res.data.response.result.data.description);
        setLike(res.data.response.result.data.likes);
        setLikeActive(res.data.response.result.isLiked);
      })
      .catch((err) => {
        if (err.response.data.response.params.errmsg === "Invalid Token") {
          navigator("/");
        }
      });
    localStorage.setItem("prevLocation", "CollViewPage");

    setWindowWidth(window.innerWidth);
    window.scrollTo(-10, -10);
  }, []);

  useEffect(() => {
    if (bundle[0] === undefined) {
      return;
    } else {
      getIndividualRes(
        localStorage.getItem("accessToken"),
        bundle[0]._id,
        params.get("location")
      )
        .then((res) => {
          setActiveUrl(res.data.response.result.preSingedUrl);
          setActiveType(res.data.response.result.docType);
          setActiveId(bundle[0]._id);
          setActiveDownload(res.data.response.result.downloadUrl);
          setPubDate(res.data.response.result.data.publishedDate);
          setPubBy(res.data.response.result.data.publishedBy);
          setUserType(res.data.response.result.userType);
          setActiveTitle(res.data.response.result.data.docTitle);
        })
        .catch((err) => {
          if (err.response.data.response.params.errmsg === "Invalid Token") {
            localStorage.clear();
            navigator("/");
          }
        });
    }
  }, [bundle]);

  return (
    <>
      <div className="view-container">
        <span className="coll-view_like">
          {likeActive ? (
            <AiFillLike
              className="like-active fs-2 mouse-cursor me-1"
              onClick={() => handleLikeCllick()}
            />
          ) : (
            <AiOutlineLike
              className={"like-inactive fs-2 mouse-cursor me-1"}
              onClick={() => handleLikeCllick()}
            />
          )}
          {like === undefined ? 0 : like}
        </span>
        {/* <div className="view-title--button">
          <span className="me-2 res-view_like">
            {likeActive ? (
              <AiFillLike
                className="like-active fs-2 mouse-cursor"
                onClick={() => handleLikeCllick()}
              />
            ) : (
              <AiOutlineLike
                className={"like-inactive fs-2 mouse-cursor"}
                onClick={() => handleLikeCllick()}
              />
            )}
            {like === undefined ? 0 : like}
          </span>
          {activeType !== "link" ? (
            <a
              className="btn btn-custom view-title-button--button"
              href={activeDownload}
              onClick={handleDownload}
              data-icon={<TbDownload className="ms-2 fs-5 " />}
              target={"_blank"}
            >
              Download <TbDownload className="ms-2 fs-5 col-view_download" />
            </a>
          ) : (
            ""
          )}
        </div> */}

        <div className="header-title-contaoner">
          <div className="view-title-container">
            <div className="view-title--title">
              <span className="view-header-containter--back-button">
                <MdOutlineArrowBack
                  className=" mouse-cursor resview-back-icon"
                  onClick={() => navigator(-1)}
                />
              </span>
              <div className="view-title--title-main fs-2">
                {title ? title : "Loading..."}
              </div>
            </div>
          </div>
          <div className="col-title_mobile-cont">
            <span>
              <MdOutlineArrowBack
                className=" mouse-cursor resview-back-icon"
                onClick={() => navigator(-1)}
              />
            </span>
            <span className="me-2 res-view_like">
              {likeActive ? (
                <AiFillLike
                  className="like-active fs-2 mouse-cursor"
                  onClick={() => handleLikeCllick()}
                />
              ) : (
                <AiOutlineLike
                  className={"like-inactive fs-2 mouse-cursor"}
                  onClick={() => handleLikeCllick()}
                />
              )}
              {like === undefined ? 0 : like}
            </span>
          </div>
          <div className="view-title--keywords">
            {keywords?.length !== 0
              ? keywords?.map((x) => (
                  <span
                    className="btn btn-custom me-2 mt-2 mb-2 view-comp_custom-pills"
                    onClick={() =>
                      navigator(
                        `/result?term=${x}&type=Guided&location=CollViewPage`
                      )
                    }
                  >
                    {x}
                  </span>
                ))
              : "Loading..."}
          </div>
          <div className="pb-3 view-title--desc">
            <b className="fs-5">Description</b>
            <span> {desc ? desc : "Loading..."}</span>
          </div>
        </div>
      </div>

      <div className="view-hr">
        <hr></hr>
      </div>

      {/* <div className="mobile-header">
        <div className="col-dropdown">
          <Dropdown>
            <Dropdown.Toggle
              className="btn-custom col-dd-button"
              id="dropdown-basic"
            >
              <div className="dropdropw_button-title">
                {mobileHeader}
                <div className="dropdown-icon-v-wrapper">
                  <span className="dropdown-icon-v"></span>
                </div>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {bundle === undefined
                ? "loading"
                : Object.keys(bundle).map((x) => (
                    <Dropdown.Item onClick={() => handleClick(bundle[x]._id)}>
                      {bundle[x].docTitle}
                    </Dropdown.Item>
                  ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div> */}

      <div className="col-view_body-container">
        <div className="col-view_body-scroll">
          <div className="col-view_items">
            <div className="col-view_button"> Active </div>
            {bundle === undefined
              ? "loading"
              : Object.keys(bundle).map((x) => (
                  <AccordionScroll
                    docTitle={bundle[x].docTitle}
                    activeId={activeId}
                    id={bundle[x]._id}
                    type={bundle[x].fileType}
                    handleClick={() => handleClick(bundle[x]._id)}
                    activeDownload={activeDownload}
                    plsLoginIn={plsLoginIn}
                    handleDownload={handleDownload}
                  />
                ))}
          </div>
        </div>
        <div className="col-view_body-viewer">
          {windowWidth >= 740 ? (
            <ViewController
              type={activeType}
              url={activeUrl}
              attachment={attachment}
              filename={title}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <hr className="view-footer_hr"></hr>
      <div className="view-footer footer-colview">
        <div className="colview-footer_creayedby">
          <h3>Published by</h3>
          <span> {pubBy ? pubBy : "-"}</span>
        </div>
        <div className="colview-footer_creayedby">
          <h3>Published date</h3>
          <span>{pubDate ? moment(pubDate).format("Do MMM YYYY") : "-"}</span>
        </div>
      </div>

      <div className="col-view_body-container-mobile">
        <Accordion>
          {bundle === undefined
            ? "loading"
            : Object.keys(bundle).map((i, x) => (
                <Accordion.Item
                  eventKey={i}
                  onClick={() => handleClick(bundle[x]._id)}
                >
                  <Accordion.Header>{bundle[x].docTitle}</Accordion.Header>
                  <Accordion.Body>
                    <div className="col-view_body-container-mobile-viewer">
                      {activeType === "link" ? (
                        " "
                      ) : (
                        <div className="col-view-body-container-mobile-button mb-5">
                          <a
                            target="_blank"
                            href={`/resview?id=${activeId}&location=CollViewPage`}
                            className="col-view-download-href me-2"
                          >
                            <FiExternalLink className="fs-4" />
                          </a>
                          <a
                            className="col-view-download-href"
                            onClick={handleDownload}
                          >
                            <TbDownload className="fs-4" />
                          </a>
                        </div>
                      )}
                      {activeId === bundle[x]._id && windowWidth <= 740 ? (
                        <>
                          <ViewController
                            type={activeType}
                            url={activeUrl}
                            attachment={attachment}
                            filename={title}
                          />
                          <div className="colView-footer-mobile">
                            <div className="colview-footer_creayedby">
                              <h3>Published by</h3>
                              <span> {pubBy ? pubBy : "-"}</span>
                            </div>
                            <div className="colview-footer_creayedby">
                              <h3>Published date</h3>
                              <span>
                                {pubDate
                                  ? moment(pubDate).format("Do MMM YYYY")
                                  : "-"}
                              </span>
                            </div>
                          </div>
                        </>
                      ) : (
                        "Loading..."
                      )}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
        </Accordion>
      </div>
      {/* <ViewController
        type={activeType}
        url={activeUrl}
        attachment={attachment}
        filename={title}
      /> */}
    </>
  );
};

const AccordionScroll = ({
  activeId,
  docTitle,
  activeDownload,
  id,
  handleClick,
  type,
  handleDownload,
  getExternalLink,
}) => {
  return (
    <>
      <span
        className={
          activeId === id
            ? "col-view_item col-view_item--active "
            : "col-view_item"
        }
        key={id}
      >
        <span onClick={handleClick}>{docTitle}</span>
        {type === "link" ? (
          " "
        ) : (
          <div className="col-view-download">
            <a
              target="_blank"
              href={`/resview?id=${activeId}&location=CollViewPage`}
              className="col-view-download-href me-2"
            >
              <FiExternalLink className="fs-4" />
            </a>
            <a
              className="col-view-download-href"
              onClick={handleDownload}
            >
              <TbDownload className="fs-4" />
            </a>
          </div>
        )}
      </span>
    </>
  );
};

import "./popupmodal.css";

import { Modal } from "react-bootstrap";

const PopUpModal = ({ show, title, setModalShow }) => {
  title = title.split(".");
  const handleClose = () => {
    setModalShow(false);
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body className="pop-up-modal_comp">
        <div className="pop-up-modal-text">
          <h5>{title[0]}.</h5>
          <h5>{title[1]}</h5>
        </div>
        <button className="btn btn-custom rounded" onClick={handleClose}>
          Close
        </button>
      </Modal.Body>
    </Modal>
  );
};

export { PopUpModal };

import "./forgotPassword.css";
import { useEffect } from "react";

import { Dashboard } from "../dashboard";
import { InputField } from "../ui/inputField";
import { ButtonComp } from "../ui/button";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { forgotPassword } from "../../apis";

import { checkIfSignedIn } from "../utils/checkIfSignedIn";

const ForgotPassword = ({ setModalShow, setModalTitle }) => {
  const [email, setEmail] = useState("");
  const [errMess, setErrMess] = useState("");

  const navigator = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    forgotPassword(email)
      .then(() => {
        setModalTitle("Please check your email.");
        setModalShow(true);
        setErrMess("");
        navigator("/")
      })
      .catch((err) => {
        setErrMess(err.response.data.response.params.errmsg);
      });
  };

  useEffect(() => {
    if (checkIfSignedIn()) {
      navigator("/search");
    }
    localStorage.setItem("prevLocation", "ForgotPasswordPage")
  }, []);

  return (
    <Dashboard>
      <div className="dashboard-component">
        <div className="pb-4">
          <h2 className="form-title">
          Reset your password
          </h2>
        </div>
        <small className="fs-6">
          Already have an account?
          <a
            className="px-1 signIn-link mouse-cursor aLink_color--purple"
            onClick={() => navigator("/")}
          >
            Sign-in here
          </a>
        </small>

        <form onSubmit={(event) => handleSubmit(event)} autoComplete="off" className="mt-4 dashboard-component-form" >
          <InputField
            title="Email"
            value={email}
            type="email"
            onChange={setEmail}
            isRequired={true}
            errorMess={errMess}
            customErrMessClass="text-danger"
          />
          <ButtonComp
            title="Send Reset Link"
            onClick={handleSubmit}
            customStyle="btn-custom my-3 w-100 search-comp_pills singin"
            disabled={email == "" ? true : false}
          />
        </form>
      </div>
    </Dashboard>
  );
};

export { ForgotPassword };

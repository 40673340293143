import { useState } from "react";
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";

import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/js/bootstrap.js";
import "./components/css/body.css";
import { useEffect } from "react";
import { HeaderComp } from "./components/header";
import { FooterComp } from "./components/footer";

import { SignInComp } from "./components/signin";
import { SignUpComp } from "./components/signup";
import { UpdateComp } from "./components/update";
import { ActivationComp } from "./components/activation";
import { ForgotPassword } from "./components/forgotPassword";
import { SearchComp } from "./components/search";
import { ResultComp } from "./components/result/result";
import { ResViewComp } from "./components/view/resView";
import { CollResView } from "./components/view/collView";
import {
  Faq,
  PrivacyPolicy,
  TncComp,
  Cg,
  LicensePolicy,
  ContactUs,
} from "./components/extra";

import { checkIfSignedIn } from "./components/utils/checkIfSignedIn";

import { getUserDetails } from "./apis";

import { PopUpModal } from "./components/ui/popupModal";

const App = () => {
  const navigator = useNavigate();
  const location = useLocation();
  const [params] = useSearchParams();
  const [modalTitle, setModalTitle] = useState("Check your email");
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 10);
    if (
      [
        "/",
        "/faq",
        "/signup",
        "/forgot",
        "/ContactUs",
        "/activation",
        "/PrivacyPolicy",
        "/LicensePolicy",
        "/termsandconditions",
        "/ContentPolicyCommunityGuidelines",
      ].includes(location.pathname)
    ) {
      return;
    } else if (!checkIfSignedIn()) {
      localStorage.setItem("id", params.get("id"));
      localStorage.setItem("id_loc", location.pathname);
      localStorage.setItem("prevLocation", "SigninPage");
      setModalShow(true);
      setModalTitle("Please sign in to continue.");
      navigator("/");
    }
    getUserDetails(localStorage.getItem("accessToken"))
      .then((res) => {
        if (res.data.response.result.isGuest) {
          return;
        } else if (!res.data.response.result.data.isUpdated) {
          if (location.pathname !== "/update") {
            setModalShow(true);
            setModalTitle("Please update your profile.");
            navigator("/update");
          }
        }
      })
      .catch((err) => {
        if (err.response.data.response.params.errmsg === "Invalid token") {
          localStorage.clear();
          localStorage.setItem("id", params.get("id"));
          localStorage.setItem("id_loc", location.pathname);
          localStorage.setItem("prevLocation", "SigninPage");
          setModalShow(true);
          setModalTitle("Please sign in to continue.");
          navigator("/");
        }
      });
  }, [location]);
  return (
    <div className="wrapper">
      <PopUpModal
        show={modalShow}
        setModalShow={setModalShow}
        title={modalTitle}
      />
      <HeaderComp />
      <Routes>
        <Route path="*" element={<SearchComp />} />
        <Route path="/" element={<SignInComp />} />
        <Route
          path="/signup"
          element={
            <SignUpComp
              setModalShow={setModalShow}
              setModalTitle={setModalTitle}
            />
          }
        />
        <Route path="/activation" element={<ActivationComp />} />
        <Route
          path="/forgot"
          element={
            <ForgotPassword
              setModalShow={setModalShow}
              setModalTitle={setModalTitle}
            />
          }
        />
        <Route
          path="/update"
          element={
            <UpdateComp
              setModalShow={setModalShow}
              setModalTitle={setModalTitle}
            />
          }
        />

        <Route
          path="/search"
          element={
            <SearchComp
              setModalShow={setModalShow}
              setModalTitle={setModalTitle}
            />
          }
        />
        <Route
          path="/result"
          element={
            <ResultComp
              p
              setModalShow={setModalShow}
              setModalTitle={setModalTitle}
            />
          }
        />
        <Route
          path="/resview"
          element={
            <ResViewComp
              setModalShow={setModalShow}
              setModalTitle={setModalTitle}
            />
          }
        />
        <Route
          path="/colview"
          element={
            <CollResView
              setModalShow={setModalShow}
              setModalTitle={setModalTitle}
            />
          }
        />
        <Route
          path="/faq"
          element={
            <Faq setModalShow={setModalShow} setModalTitle={setModalTitle} />
          }
        />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/termsandconditions" element={<TncComp />} />
        <Route path="/ContentPolicyCommunityGuidelines" element={<Cg />} />
        <Route path="/LicensePolicy" element={<LicensePolicy />} />
        <Route
          path="/ContactUs"
          element={
            <ContactUs
              setModalShow={setModalShow}
              setModalTitle={setModalTitle}
            />
          }
        />
      </Routes>
      <FooterComp />
    </div>
  );
};

export default App;

const InputField = ({
  title,
  value,
  type,
  customStyle,
  onChange,
  onBlur,
  isRequired,
  onKeyDown,
  errorMess,
  customErrMessClass,
  bottomText
}) => {
  let customErrClass = `form-text ${customErrMessClass}`;
  let customClass = `form-control ${customStyle}`;
  return (
    <div className="form-outline mb-4">
      <label className="form-label flex-column display-flex">
        <span className="input-form-label">
          <div>
            {title}
            {isRequired ? (
              <>
                <span className="text-danger">
                  <sup>*</sup>
                </span>
              </>
            ) : (
              ""
            )}
          </div>
          <div><small> {bottomText}</small></div>
        </span>
      </label>
      <input
        type={type}
        className={customClass}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        required={isRequired}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        autoComplete="new-password"
        maxLength="50"
      />
      {errorMess ? <div className={customErrClass}>{errorMess}</div> : ""}
    </div>
  );
};

export { InputField };

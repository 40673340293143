import "./view.css";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { saveAs } from 'file-saver';

import {
  getIndividualRes,
  downloadCounter,
  likeCounter,
} from "../../../apis/apis";

import { ViewController } from "../../ui/ModalViewer/viewController";

import { MdOutlineArrowBack } from "react-icons/md";
import { TbDownload } from "react-icons/tb";
import { AiOutlineLike, AiFillLike } from "react-icons/ai";

import moment from "moment";
import { type } from "@testing-library/user-event/dist/type";

export const ResViewComp = ({ setModalShow, setModalTitle }) => {
  const navigator = useNavigate();
  const [params] = useSearchParams();
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [desc, setDesc] = useState("");
  const [keywords, setKeywords] = useState("");
  const [attr, setAttr] = useState("");
  const [licence, setLicence] = useState("");
  const [attachment, setAttachment] = useState("");
  const [publishedDate, setPublishedDate] = useState("");
  const [like, setLike] = useState(0);
  const [likeActive, setLikeActive] = useState();

  const [userType, setUserType] = useState("");

  const [url, setUrl] = useState("");
  const [downloadUrl, setDownloadUrl] = useState("");
  const [docType, setDocType] = useState("");

  const handleDownload = async (e) => {
    const downloadResult = await fetch(downloadUrl);
    const blob = await downloadResult.blob();
    saveAs(blob, `${title}${docType}`);
    downloadCounter(
      localStorage.getItem("accessToken"),
      params.get("id"),
      "ResViewPage"
    );
  };

  const handleLikeCllick = (e) => {
    if (userType === "Guest") {
      plsLoginIn("like");
      return;
    }
    if (likeActive) {
      setLikeActive(!likeActive);
      setLike((prev) => prev - 1);
      likeCounter(
        localStorage.getItem("accessToken"),
        params.get("id"),
        "ResViewPage"
      );
    } else {
      if (like === undefined) {
        setLike(1);
      } else {
        setLike((prev) => prev + 1);
      }
      setLikeActive(!likeActive);
      likeCounter(
        localStorage.getItem("accessToken"),
        params.get("id"),
        "ResViewPage"
      );
    }
  };

  const plsLoginIn = (message) => {
    setModalTitle(`Please sign in to ${message}`);
    setModalShow(true);
  };

  useEffect(() => {
    localStorage.setItem("prevLocation", "ResViewPage");
    getIndividualRes(
      localStorage.getItem("accessToken"),
      params.get("id"),
      params.get("location")
    )
      .then((res) => {
        setTitle(res.data.response.result.data.docTitle);
        setAuthor(res.data.response.result.data.publishedBy);
        setDesc(res.data.response.result.data.docDesc);
        setUrl(res.data.response.result.preSingedUrl);
        setDownloadUrl(res.data.response.result.downloadUrl);
        setDocType(res.data.response.result.docType);
        setKeywords(res.data.response.result.data.keywords);
        setAttr(res.data.response.result.data.attribution);
        setPublishedDate(res.data.response.result.data.publishedDate);
        setKeywords(res.data.response.result.data.keywords);
        setLicence(res.data.response.result.data.licence);
        setAttachment(res.data.response.result.data.attachment);
        setLike(res.data.response.result.data.likes);
        setLikeActive(res.data.response.result.isLiked);
        setUserType(res.data.response.result.userType);
      })
      .catch((err) => {
        if (err.response.data.response.params.errmsg === "Invalid Token") {
          localStorage.clear();
          navigator("/");
        }
      });
  }, []);

  return (
    <>
      <div className="view-container">
        <div className="view-title--button">
          <span className="me-2 res-view_like">
            {likeActive ? (
              <AiFillLike
                className="like-active fs-2 mouse-cursor"
                onClick={() => handleLikeCllick()}
              />
            ) : (
              <AiOutlineLike
                className={"like-inactive fs-2 mouse-cursor"}
                onClick={() => handleLikeCllick()}
              />
            )}
            {like === undefined ? 0 : like}
          </span>
          {docType !== "link" ? (
            <button
              className="btn btn-custom view-title-button--button"
              onClick={handleDownload}
              data-icon={<TbDownload className="ms-2 fs-5 " />}
            >
              Download <TbDownload className="ms-2 fs-5 col-view_download" />
            </button>
          ) : (
            ""
          )}
        </div>

        <div className="header-title-contaoner">
          <div className="view-title-container">
            <div className="view-title--title">
              <span className="view-header-containter--back-button">
                <MdOutlineArrowBack
                  className=" mouse-cursor resview-back-icon"
                  onClick={() => navigator(-1)}
                />
              </span>
              <div className="view-title--title-main fs-2">
                {title ? title : "Loading..."}
              </div>
            </div>
            <div className="view-title_mobile-cont">
              <span>
                <MdOutlineArrowBack
                  className=" mouse-cursor resview-back-icon"
                  onClick={() => navigator(-1)}
                />
              </span>
              <span className="me-2 res-view_like">
                {likeActive ? (
                  <AiFillLike
                    className="like-active fs-2 mouse-cursor"
                    onClick={() => handleLikeCllick()}
                  />
                ) : (
                  <AiOutlineLike
                    className={"like-inactive fs-2 mouse-cursor"}
                    onClick={() => handleLikeCllick()}
                  />
                )}
                {like === undefined ? 0 : like}
                {docType === "link" ? (
                  ""
                ) : (
                  <button
                    className="btn btn-custom view-title-button--button ms-3"
                    onClick={handleDownload}
                    data-icon={<TbDownload className="ms-2 fs-5 " />}
                  >
                    Download <TbDownload className="ms-2 fs-5 " />
                  </button>
                )}
              </span>
            </div>
          </div>
          <div className="view-title--keywords">
            {keywords.length !== 0
              ? keywords.map((x) => (
                  <span
                    className="btn btn-custom me-2 mt-2 mb-2 view-comp_custom-pills"
                    onClick={() =>
                      navigator(
                        `/result?term=${x}&type=Guided&location=ResViewPage`
                      )
                    }
                  >
                    {x}
                  </span>
                ))
              : "Loading..."}
          </div>
          <div className="pb-3 view-title--desc">
            <b className="fs-5">Description</b>
            <span> {desc ? desc : "Loading..."}</span>
          </div>
        </div>
      </div>

      <div className="view-hr">
        <hr></hr>
      </div>

      <div className="view-body--wrapper">
        <div className="view-body">
          <ViewController
            url={url}
            type={docType}
            attachment={attachment}
            filename={title}
          />
        </div>
      </div>

      <hr className="view-footer_hr"></hr>
      <div className="view-footer">
        <div className="view-footer_attr">
          <h3>Attribution</h3>
          <span> {attr ? attr : "-"} </span>
        </div>
        <div className="view-footer_creayedby">
          <h3>Published by</h3>
          <span> {author ? author : "-"}</span>
        </div>
        <div className="view-footer_creayedby">
          <h3>Published date</h3>
          <span>
            {publishedDate ? moment(publishedDate).format("Do MMM YYYY") : "-"}
          </span>
        </div>
        <div className="view-footer_licensce">
          <h3>License</h3>
          <span>{licence ? licence : "-"}</span>
        </div>
      </div>
    </>
  );
};

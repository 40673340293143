import "./footer.css";
import { useNavigate } from "react-router-dom";

import {
  AiOutlineTwitter,
  AiFillLinkedin,
  AiFillYoutube,
} from "react-icons/ai";

const FooterComp = () => {
  const navigator = useNavigate();

  return (
    <div className="d-flex footer-wrapper">
      <div className="footer-wrapper__address">
        C4EC Foundation
        <br />
        Kumar's, No. 86, 7th Cross, 4th Block,<br />
        Koramangala, Bangalore 560034<br />
        Karnataka, INDIA
        <br />
        <a href="https://societalplatform.org/"> space@societalplatform.org </a>
      </div>
      <div className="d-flex flex-column footer-wrapper__tnc">
        <span
          className="mouse-cursor footer-links"
          onClick={() => navigator("/PrivacyPolicy")}
        >
          Data Privacy
        </span>
        <span
          className="mouse-cursor footer-links"
          onClick={() => navigator("/termsandconditions")}
        >
          Terms and Conditions
        </span>
        <span
          className="mouse-cursor footer-links"
          onClick={() => navigator("/ContentPolicyCommunityGuidelines")}
        >
          Content Policy and Data Conditions
        </span>
        <span
          className="mouse-cursor footer-links"
          onClick={() => navigator("/LicensePolicy")}
        >
          License Policy
        </span>
        <div className="footer-icons">
          <a href="https://twitter.com/SocietalPlatfrm" target="_blank">
            <AiOutlineTwitter className="fs-2 footer-icon_color" />
          </a>
          <a
            href="https://www.linkedin.com/company/societal-platform/"
            target="_blank"
          >
            <AiFillLinkedin className="fs-2  footer-icon_color" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCb8I2VYk7KM_VqE4oPILIDA"
            target="_blank"
          >
            <AiFillYoutube className="fs-2  footer-icon_color" />
          </a>
        </div>
      </div>
    </div>
  );
};

export { FooterComp };

// [
//   <ul className="list-grou bg-transparent">
//   <li className="list-group-item">Data Privacy</li>
//   <li className="list-group-item">Terms and Conditions</li>
//   <li className="list-group-item">Content Policy and Data Conditions</li>
//   <li className="list-group-item">License Policy</li>
//   <li className="list-group-item">Asset Guidelines</li>
// </ul>
// ]



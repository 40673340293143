import { useNavigate } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import "./header.css";
import { useEffect, useState } from "react";

import imageUrl from "./logo.png";
import { teleEvent } from "../../apis/apis";

const HeaderComp = () => {
  const navigator = useNavigate();
  const [showProfile, setShowProfile] = useState(false);
  const [isGuest, setIsGuest] = useState(true);
  const logOut = () => {
    localStorage.clear();
    navigator("/");
  };

  const handleImageClick = () => {
    if (localStorage.getItem("accessToken")) {
      navigator("/search");
    } else {
      navigator("/");
    }
  };

  const handleTeleEvent = () => {
    window.open(
      "https://www.udemy.com/course/societal-thinking-pathway-to-exponential-change-part-1/",
      "_blank"
    );
    let url = window.location.pathname;
    let location;
    if (url === "/") {
      location = "SignInPage";
    } else if (url === "/faq") {
      location = "FaqPage";
    } else if (url === "/ContactUs") {
      location = "ContactPage";
    } else if (url === "/resview") {
      location = "ResViewPage";
    } else if (url === "/colview") {
      location = "CollViewPage";
    } else if (url === "/search") {
      location = "Homepage";
    } else if (url === "/result") {
      location = "SearchResultPage";
    } else if (url === "/update") {
      location = "ProfilePage";
    }

    teleEvent(
      localStorage.getItem("accessToken"),
      "SocietalThinkingCourse",
      location
    );
  };

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      setShowProfile(true);
    } else {
      setShowProfile(false);
    }
  }, [localStorage.getItem("accessToken")]);

  useEffect(() => {
    if (localStorage.getItem("isGuest")) {
      setIsGuest(true);
    } else {
      setIsGuest(false);
    }
  });

  return (
    <div className="container-fluid header-container">
      <div className="d-flex">
        <img
          alt="logo"
          src={imageUrl}
          className="logo mouse-cursor"
          onClick={handleImageClick}
        />
        <div className="d-flex justify-content-center ps-5"></div>
      </div>

      <div className="header-container--links">
        <div className="header-container--links--m">
          <span
            onClick={handleTeleEvent}
            className="header-container_links-items mouse-cursor nodec"
          >
            Societal Thinking Course
          </span>
          |
          <span
            className="header-container_links-items mouse-cursor"
            onClick={() => navigator("/faq")}
          >
            FAQs
          </span>{" "}
          |
          <span
            className="header-container_links-items mouse-cursor"
            onClick={() => navigator("/ContactUs")}
          >
            Contact us
          </span>
        </div>
      </div>

      {/* the profile handling */}
      {showProfile ? (
        <Profile
          logOut={logOut}
          navigator={navigator}
          isGuest={isGuest}
          handleTeleEvent={handleTeleEvent}
        />
      ) : (
        ""
      )}
    </div>
  );
};
export { HeaderComp };

const Profile = ({ navigator, logOut, isGuest, handleTeleEvent }) => {
  return (
    <div className="profile-icon">
      <div className="dropdown">
        <CgProfile
          className="mouse-cursor header-component__profile"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        />
        <ul className="dropdown-menu " aria-labelledby="dropdownMenuButton1">
          {!isGuest ? (
            <li>
              <a
                className="dropdown-item mouse-cursor "
                onClick={() => navigator("/update")}
                href="#"
              >
                Profile
              </a>
            </li>
          ) : (
            ""
          )}
          <div className="header-container--links--f">
            <li
              onClick={handleTeleEvent}
              className="dropdown-item mouse-cursor"
            >
              Societal Thinking Course
            </li>
            <li
              className="dropdown-item mouse-cursor"
              onClick={() => navigator("/faq")}
            >
              FAQs
            </li>
            <li
              className="dropdown-item mouse-cursor"
              onClick={() => navigator("/ContactUs")}
            >
              Contact us
            </li>
          </div>
          <li>
            <a className="dropdown-item mouse-cursor" href="#" onClick={logOut}>
              {!isGuest ? "Logout" : "Sign in"}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

import { useEffect } from "react";
export const Cg = () => {
  useEffect(() => {
  }, []);
  return (
    <div className="pp-comp">
      <center>
        <b>Content Policy & Community Guidelines</b>
      </center>
      <p>
        Effective Date: 1<sup>st</sup> June, 2021
      </p>

      <p>
        The purpose of this Content Policy & Community Guidelines is to ensure
        that the quality of the Content on the Platform meets the following
        fundamental quality criteria. Set out below are some common-sense rules
        that all Users must comply with. These rules are to be taken seriously
        as administrators of the Platform at EkStep are required to enforce the
        same strictly. Users are requested not to look for loopholes or ways
        around these guidelines and respect the spirit in which they have been
        created.
      </p>

      <ol type="1">
        <li className="pp_li">
          <b>Respect and Trust:</b> When you use Platform, you join a vast
          community of Users. Every community feature on the Platform involves a
          certain level of trust. Users are expected to respect that trust and
          be responsible about their usage of the Platform and all its Content.
        </li>
        <li className="pp_li">
          <b>Compliance:</b> As a User, you agree that while using the Platform,
          contributing any Content on the Platform, including postings,
          feedback, questions, responses, comments any other form of community
          engagement you shall strictly adhere to this Content Policy &
          Community Guidelines, Terms of Use and Privacy Policy. You shall be
          solely responsible for Content contributed using your User ID and
          password on the Platform.
        </li>

        <li className="pp_li">
          <b>Appropriateness and Accuracy:</b> You must ensure that all Content
          contributed by you on the Platform is appropriate and accurate for use
          as outlined by the following guidelines. Content identified as
          inappropriate or inaccurate as per these guidelines will not be
          allowed on the Platform and if published can be unilaterally removed
          from the Platform by the administrators.
          <ol type="a">
            <li className="pp_li">
              <b>Inappropriate Content</b> includes, but is not limited to:
              <ol type="i">
                <li className="pp_li">
                  Hate Speech - by words either written or spoken or by signs or
                  by visible representation or otherwise promotes or attempts to
                  promote feelings of enmity, hatred or ill-will against persons
                  of a:
                  <ol type="1">
                    <li className="pp_li">Caste</li>
                    <li className="pp_li">Class</li>
                    <li className="pp_li">Tribe</li>
                    <li className="pp_li">Race</li>
                    <li className="pp_li">Ethnicity</li>
                    <li className="pp_li">Sex, gender, or gender identity</li>
                    <li className="pp_li">National origin</li>
                    <li className="pp_li">Religious affiliation</li>
                    <li className="pp_li">Sexual orientation, or</li>
                    <li className="pp_li">Disabilities or diseases</li>
                  </ol>
                </li>
                <li className="pp_li">
                  <li className="pp_li">Sexually explicit content</li>
                  <ol type="1">
                    <li className="pp_li">Pornography</li>
                    <li className="pp_li">
                      Explicit text/images/illustrations/sounds of sexual
                      content
                    </li>
                    <li className="pp_li">Descriptions of sexual acts</li>
                  </ol>
                </li>
                <li className="pp_li">
                  Depiction of sexual violence and exploitation
                  <ol type="1">
                    <li className="pp_li">
                      Includes sexual exploitation of minors, and sexual
                      assault.
                    </li>
                    <li className="pp_li">
                      Photographs/ Illustrations/videos depicting incidents of
                      sexual violence
                    </li>
                  </ol>
                </li>

                <li className="pp_li">
                  Depiction of nudity
                  <ol type="1">
                    <li className="pp_li">
                      Nudity would mean displaying genitals, breasts or buttocks
                      (clothed or unclothed) for the purpose of sexual
                      gratification.
                    </li>
                    <li className="pp_li">
                      Other than the depiction of body parts which isn’t
                      gratuitously graphic and is educational, documentary,
                      scientific, or artistic - paintings, sculptures, and other
                      art that depicts nude figures, which is posted for
                      educational purposes. Providing context will help users
                      determine the purpose of the content/asset.
                    </li>
                  </ol>
                </li>

                <li className="pp_li">
                  Content that promotes violence, including
                  <ol type="1">
                    <li className="pp_li">
                      Promoting, encouraging, supporting, praising, or condoning
                      violent actions, activities and behaviour - verbal,
                      physical or emotional.
                    </li>
                    <li className="pp_li">
                      Threatening or inciting others to commit acts of violence
                    </li>
                    <li className="pp_li">
                      Expressing support or praise for groups, people that are
                      involved in violent or criminal behaviour
                    </li>
                  </ol>
                </li>

                <li className="pp_li">
                  Content that promotes/depicts/reflects discrimination and
                  bullying or encouraging such behaviour, including:
                  <ol type="1">
                    <li className="pp_li">
                      Targeting individuals with the intention of degrading or
                      shaming them.
                    </li>
                    <li className="pp_li">
                      Illustrations or Images altered to degrade individuals
                    </li>
                    <li className="pp_li">
                      Photos or videos of physical or verbal bullying
                    </li>
                    <li className="pp_li">
                      Sharing personal information or harassing people
                    </li>
                    <li className="pp_li">
                      Repeatedly targeting other people with unwanted requests
                      or messages.
                    </li>
                  </ol>
                </li>

                <li className="pp_li">
                  Comprises harmful or dangerous content
                  <ol type="1">
                    <li className="pp_li">
                      which intends to incite violence or encourage dangerous or
                      illegal activities that have an inherent risk of serious
                      physical harm or death.
                    </li>
                    <li className="pp_li">
                      Encourages dangerous or illegal activities for instance -
                      money laundering, gambling, performing stunts, high risk
                      activities, choking games, drug use, or other acts where
                      serious injury or harm may result. Content that depicts
                      dangerous acts may be allowed if the primary purpose is
                      educational, documentary, scientific, or artistic (EDSA),
                      and isn’t gratuitously graphic.
                    </li>
                  </ol>
                </li>

                <li className="pp_li">
                  Other possibilities:
                  <ol type="1">
                    <li className="pp_li">
                      Content which violates the law for the time being in
                      force,
                    </li>
                    <li className="pp_li">
                      Content which infringes any trademark, copyright, or other
                      intellectual property rights,
                    </li>
                    <li className="pp_li">
                      Content which deceives or misleads the User about the
                      origin of such Content and conveys information which is
                      grossly offensive or menacing,
                    </li>
                    <li className="pp_li">
                      Content which contains software viruses or any other
                      computer code, files or programs designed to interrupt,
                      destroy or limit the functionality of any computer
                      resource,
                    </li>
                    <li className="pp_li">
                      Content which threatens the unity, integrity, defence,
                      security or sovereignty of India, friendly relations with
                      foreign states, or public order, or causes incitement to
                      the commission of any cognisable offence or prevents
                      investigation of any offence or is insulting any other
                      nation,
                    </li>
                    <li className="pp_li">
                      Content involvings minors/ children in violence as victims
                      or perpetrators or as forced witnesses to violence, or
                      showing children as being subjected to any form of child
                      abuse,
                    </li>
                    <li className="pp_li">
                      Content involving abuse or ridicule of people with
                      disabilities (physical or mental),
                    </li>
                    <li className="pp_li">
                      Content involving cruelty to, or abuse of animals,
                    </li>
                    <li className="pp_li">
                      depiction of violence, cruelty and horror, scenes of
                      violence primarily,
                    </li>
                    <li className="pp_li">
                      Content involving images/text/ illustrations have the
                      effect of encouraging, justifying, glorifying, glamorising
                      alcohol drinking, drugs and substance abuse; consumption
                      of tobacco or smoking,
                    </li>
                    <li className="pp_li">
                      Content involving degrading or denigrating women in any
                      manner,
                    </li>
                    <li className="pp_li">
                      Content involving vulgarity, obscenity or depravity,
                    </li>
                    <li className="pp_li">
                      Content involving dual meaning words as obviously cater to
                      baser instincts,
                    </li>
                    <li className="pp_li">
                      Content involving visuals or words contemptuous of racial,
                      religious or other groups,
                    </li>
                    <li className="pp_li">
                      Content involving visuals or words which promote
                      superstition, communal, obscurantist, anti-scientific
                      attitude, and
                    </li>
                    <li className="pp_li">
                      Content involving visuals or words involving defamation of
                      an individual or a body of individuals.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li className="pp_li">
              <b>Accurate Content </b> - All Content on the Platform must:
              <ol type="i">
                <li className="pp_li">Be factually correct,</li>
                <li className="pp_li">
                  Use language in text, audio, video and interactive materials
                  that is simple and easy to understand, and
                </li>
                <li className="pp_li">
                  Be contextual and relevant to the needs of the intended User.
                </li>
              </ol>
            </li>
          </ol>
        </li>

        <li className="pp_li">
          <b>Intellectual Property Rights</b>
          <ol type="a">
            <li className="pp_li">
              Users agree to comply with all copyright laws worldwide in the use
              of the Platform and to prevent unauthorised copying of any
              Content.
            </li>
            <li className="pp_li">
              Users are solely responsible for Content they have created,
              curated, reviewed, or published on the Platform.
            </li>
            <li className="pp_li">
              Users must ensure that Content that they create, curate, review or
              publish on the Platform reflects correct and accurate details in
              relation to the:
            </li>
            <ol type="i">
              <li className="pp_li">Copyright details</li>
              <li className="pp_li">
                Attributions to creators whose content has been relied on, used
                in, or referred to in the Content (if any) and sources and
                citations.
              </li>
              <li className="pp_li">License details</li>
              <li className="pp_li">
                Any other legal or proprietary notice in relation to the
                Content.
              </li>
            </ol>

            <li className="pp_li">
              Users shall not falsify or delete such copyright management
              information.
            </li>
            <li className="pp_li">
              Users, who have copyright in the Content they have contributed,
              consent to publication of their Content in accordance with the
              Terms of Use and this Content Policy.{" "}
            </li>
            <li className="pp_li">
              In relation to Content being contributed by Users who are not
              copyright holders of the Content, such contributors must ensure
              that they have the necessary authorisation, license, or permission
              to publish such Content in accordance with the Terms of Use and
              this Content Policy.
            </li>
            <li className="pp_li">
              Users may Use the Content on the Platform as per the license
              conditions of the Content. Any unauthorized use of the Platform or
              its Contents shall lead to termination of the access granted by
              EkStep.
            </li>
            <li className="pp_li">
              The Platform does not permit or encourage copyright infringing
              activities and infringement of intellectual property rights on the
              Platform, and EkStep will remove any Content if properly notified
              that such Content infringes on another's intellectual property
              rights.
            </li>
            <li className="pp_li">
              Any violations of copyright laws will be the responsibility of the
              concerned Users. Any disputes will be settled by the respective
              parties. The Platform and EkStep bear no responsibility for this.
            </li>
          </ol>
        </li>

        <li className="pp_li">
          <b>Prohibited Content:</b> In relation to the Content contributed on
          the Platform, Users shall not:
          <ol type="a">
            <li className="pp_li">
              Reveal any personally identifiable data or sensitive personal
              information of themselves or any other person, unless due consent
              has been obtained for the same;
            </li>
            <li className="pp_li">
              Reveal Confidential or proprietary information of any third party;
            </li>
            <li className="pp_li">
              Contribute any Content which infringes intellectual property
              rights of any third parties.
            </li>
          </ol>
        </li>

        <li className="pp_li">
          <b>No Endorsement:</b> The Platform and EkStep do not endorse any
          Content on the Platform by any User, or any opinion, recommendation,
          or advice expressed therein, and the Platform and EkStep expressly
          disclaims any and all liability in connection with the Content.
        </li>

        <li className="pp_li">
          <b>Right not to Publish and Removal of Content:</b> EkStep reserves
          the right to remove Content without prior notice for
          inappropriateness, inaccuracy, or violations of any kind by any User.
        </li>

        <li className="pp_li">
          <b>Complaints & Grievance Redressal:</b>
          <ol type="a">
            <li className="pp_li">
              We, at EkStep, take compliance with applicable laws, including
              intellectual property rights as well as this Content Policy &
              Community Guidelines, very seriously. We take all reasonable steps
              necessary to ensure that the Content contributed by Users do not
              violate these.{" "}
            </li>
            <li className="pp_li">
              In the event, that you feel that any Content on the Platform
              violates any applicable laws, including intellectual property
              rights or the Content Policy & Community Guidelines, please write
              to the Grievance Redressal Officer of EkStep at
              space@soceitalplatform.org with the subject: “SPace: Grievance
              Issue” and the following details in your email:
            </li>
            <ol type="i">
              <li className="pp_li">Your name and email address</li>
              <li className="pp_li">
                Name of the organization you represent (if applicable)
              </li>
              <li className="pp_li">
                Link to the Content on the Platform which allegedly violates
                applicable laws or this Policy;
              </li>
              <li className="pp_li">
                If applicable, the source of the third-party intellectual
                property being infringed (please provide us with the link to the
                source being infringed, if the source is available online or the
                details of the book or the excerpt or journal or the material
                comprising such third-party intellectual property and the
                details of the author whose intellectual property rights are
                being allegedly infringed)
              </li>
              <li className="pp_li">
                A brief description of the type of violation; and
              </li>
              <li className="pp_li">
                Any other information you wish to provide us with.
              </li>
            </ol>
            <li className="pp_li">
              Upon receipt of a complaint in the manner set out above, EkStep
              reserves the right to remove the Content, in question, from the
              Platform within 14 (fourteen) business days.
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};

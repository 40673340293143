import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { InputField } from "../ui/inputField";

import { FcGoogle } from "react-icons/fc";
import { AiFillApple } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import { ButtonComp } from "../ui/button";
import { DividerLine } from "../ui/DividerLine";

import { Dashboard } from "../dashboard";

import { checkIfEmailExists, signUp, signInasGuest } from "../../apis";
import { checkIfSignedIn } from "../utils/checkIfSignedIn";

const SignUpComp = ({ setModalShow, setModalTitle }) => {
  const navigator = useNavigate();
  const [email, setEmail] = useState("");
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [mess, setMess] = useState("");

  let returnid = localStorage.getItem("id");
  let returnLoc = localStorage.getItem("id_loc");
  let fromLoc = localStorage.getItem("prevLocation");

  const handleSubmit = (event) => {
    event.preventDefault();
    signUp(fname, lname, email)
      .then((res) => {
        if (res.data.response.params.status === "successful") {
          setModalTitle("Please check your email.");
          setModalShow(true);
          navigator("/");
        }
      })
      .catch((err) => {
        if (err.response.data.response.params.status === "failed") {
          setMess(err.response.data.response.params.errmsg);
        }
      });
  };

  const handleSigninAsGuest = () => {
    signInasGuest().then((res) => {
      if (res.data.response.result.message === "Guest Login successful") {
        localStorage.setItem(
          "accessToken",
          res.data.response.result.accessToken
        );
        localStorage.setItem("isGuest", true);
        if (returnid !== null && returnLoc !== null && fromLoc !== null) {
          navigator(`${returnLoc}?id=${returnid}&location=${fromLoc}`);
        } else {
          navigator(`/search`);
        }
      }
    });
  };

  const checkEmail = () => {
    checkIfEmailExists(email)
      .then((res) => {
        if (!res.data.response.result.isAvaliable) {
          setMess("This email is not available");
        } else {
          setMess("");
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (checkIfSignedIn()) {
      navigator("/search");
    }
    localStorage.setItem("prevLocation", "SignUpPage");
  }, []);

  return (
    <Dashboard>
      <div className="dashboard-component">
        <div className="pb-4">
          <h2 className="form-title">Sign Up</h2>
          <small className="fs-6">
            Already have an account?
            <a
              className="px-1 signIn-link mouse-cursor aLink_color--purple"
              onClick={() => navigator("/")}
            >
              Sign-in here
            </a>
          </small>
        </div>

        <form onSubmit={handleSubmit} autoComplete="off">
          <div className="d-flex">
            <InputField
              title="First Name"
              value={fname}
              type="text"
              onChange={setFName}
              isRequired={true}
              customStyle="me-1"
            />

            <InputField
              title="Last Name"
              value={lname}
              type="text"
              onChange={setLName}
              customStyle="ms-1"
              isRequired={true}
            />
          </div>
          <InputField
            title="Email"
            value={email}
            type="email"
            onChange={setEmail}
            onBlur={checkEmail}
            isRequired={true}
            errorMess={mess}
            customErrMessClass="text-danger"
          />

          <label className="form-check-label">
            By clicking Sign Up, you agree to our{" "}
            <a
              className="mouse-cursor me-1"
              onClick={() => navigator("/termsandconditions")}
            >
              Terms
            </a>
            and
            <a
              className="mouse-cursor me-1"
              onClick={() => navigator("/PrivacyPolicy")}
            >
              {" "}
              Data Policy
            </a>
          </label>
          <ButtonComp
            title="Sign Up"
            onClick={handleSubmit}
            customStyle="btn-custom my-3 w-100 search-comp_pills"
            disabled={
              email.length < 5 || fname.length < 3 || lname.length < 1
                ? true
                : false
            }
          />
        </form>

        <DividerLine />

        <div className="d-flex flex-column signIn__btn--options">
          {/* <ButtonComp
            title="Sign in with Google"
            customStyle="btn-white my-1 border"
            icon={<FcGoogle className="icon-size mx-2" />}
            disabled={true}
          ></ButtonComp>
          <ButtonComp
            title="Sign in with Apple"
            customStyle="btn-white my-1 border"
            icon={<AiFillApple className="icon-size mx-2" />}
            disabled={true}
          ></ButtonComp> */}
          <ButtonComp
            onClick={handleSigninAsGuest}
            title="Sign in as a Guest"
            customStyle="btn-white my-1 border"
            icon={<FaUserAlt className="icon-size mx-2" />}
          ></ButtonComp>
        </div>
      </div>
    </Dashboard>
  );
};

export { SignUpComp };

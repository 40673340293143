import "./search.css";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";

import { getCommonSearchTerms, getQuestions, latestRes } from "../../apis";

const SearchComp = ({ setModalShow, setModalTitle }) => {
  const [pills, setPills] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [questions, setQuestion] = useState([]);
  const [count, setCount] = useState(0);
  const [lastestRes, setLastestRes] = useState([]);
  const navigator = useNavigate();
  const handleSeachButton = (event) => {
    event.preventDefault();
    if (event.key === "Enter") {
      navigator(
        `/result?term=${encodeURIComponent(
          searchInput
        )}&type=Organic&location=Homepage`
      );
    }

    navigator(
      `/result?term=${encodeURIComponent(
        searchInput
      )}&type=Organic&location=Homepage`
    );
  };

  const handlePillCLick = (x) => {
    navigator(`/result?term=${x}&type=Guided&location=Homepage`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getQuestions().then((res) => {
      setQuestion(res.data.response.result.data);
    });
    getCommonSearchTerms().then((res) =>
      setPills(res.data.response.result.data)
    );

    latestRes().then((res) => setLastestRes(res.data.response.result.data));
    localStorage.setItem("prevLocation", "Homepage");
    localStorage.setItem("currFilter", "All");
    localStorage.setItem("rellFilter", "Relevance");
    localStorage.setItem("catFilter", "All");
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevState) => prevState + 1);
    }, 8000);
    localStorage.setItem("view", "res");
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (count >= questions.length) {
      setCount(0);
    }
  }, [count]);

  return (
    <>
      <div className="seach-comp_background--wrapper">
        <div className="seach-comp_background" />
        <div className="seach-comp_background--text">
          {questions?.[count]?.question}
        </div>
      </div>
      <div className="search_container">
        <form onSubmit={(event) => handleSeachButton(event)} autoComplete="off">
          <div className="input-group input-group-lg d-flex search-input">
            <button
              className="input-group-text search-comp_icon"
              onClick={(event) => handleSeachButton(event)}
            >
              <AiOutlineSearch className="search-comp_icon-icon" />
            </button>
            <input
              type="text"
              className="form-control search-comp_input "
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
              required
            />
          </div>

          <div className="search-comp_pills-container mt-3">
            {pills.map((x) => (
              <span
                key={x}
                className="btn-custom btn me-2 search-comp_pills"
                onClick={() => handlePillCLick(x)}
              >
                {x}
              </span>
            ))}
          </div>
        </form>
      </div>
      <div className="search-comp-resource">
        <div className="search-comp-resource--header">
          <span className="fs-6">Latest Resources</span>
        </div>
        <div className="search-comp-resource--items mb-3">
          {lastestRes.map((x) => (
            <div
              className="search-comp-resource--item mouse-cursor truncate"
              key={x._id}
              onClick={() =>
                navigator(`/resview?id=${x._id}&location=Homepage`)
              }
            >
              {x?.docTitle}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export { SearchComp };

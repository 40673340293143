import { useEffect } from "react";
import "./pp.css";
export const PrivacyPolicy = () => {
  useEffect(() => {
    document.querySelector('body').scrollTo(0,0)
  }, [])
  return (
    <div className="pp-comp">
      <center>
        <b>
          <h3> Privacy Policy</h3>
        </b>
      </center>

      <p>Effective Date: 15<sup>th</sup> July, 2020</p>

      <p>
        We respect your privacy and are committed to protecting the privacy of
        our Users.
      </p>

      <p>
        Please read this Privacy Policy carefully, to learn more about the ways
        in which the Platform uses and protects your information and data. This
        Privacy Policy covers the information and data that is collected from
        its User(s) on the Platform. The terms ‘you’, ‘your’ refer to any User
        of the Platform.
      </p>

      <p>
        By using the Website and providing your information on the Platform, you
        consent to the collection and use of the information you disclose by the
        Platform in accordance with the Terms of Use and this Privacy Policy.
        You acknowledge that the collection, storage and processing of your
        information or data on the Platform is for a lawful purpose connected
        with functioning and activity on the Platform. If you do not agree with
        the contents of this policy, please do not access or use the Platform.
      </p>

      <p>
        This Privacy Policy should be read in conjunction and together with the
        Terms of Use. Defined terms used but not defined herein shall have the
        meaning ascribed to them in the Terms of Use. We hereby notify you the
        following information about your personal data processing:{" "}
      </p>

      <ol type="1">
        <li className="pp_li">
          <ol type="a">
            <li className="pp_li">
              <b>Types of information we collect:</b> We collect the following
              types of information on the Platform (hereinafter collectively
              referred to as the “Personal Data”):
            </li>
            <li className="pp_li">
              <b>Mandatory:</b> name, email address, display picture, time spent
              on Content, Badges, Interests, likes, feedback, Usage history,
              Content created/ contributed / published and their usage, goals
              and playlists, votes, contributions made on blogs and social
              forums (Q&A, comments, posts), courses & programs completed,
              scores & assessment result.
            </li>
            <li className="pp_li">
              <b>Optional:</b> name of organization, social media account
              linkage, device id
            </li>
            <li className="pp_li">
              No other personally identifiable information or sensitive personal
              information or data of the User is collected.{" "}
            </li>
          </ol>
        </li>
        <li className="pp_li">
          <b>Data recipients/ accessible to:</b> EkStep internal teams handling
          the administration of the Platform, authorized third party service
          providers (including Cloud providers).
        </li>
        <li className="pp_li">
          <b>
            Lawfulness of Processing, Purpose of collection and Use of your
            Personal Data: We process your Personal when it is necessary for the
            purposes of a legitimate interest pursued by EkStep or a third party
            (when these interests are not overridden by your data protection
            rights). This applies to the following uses of your Information:
          </b>
          <ol type="a">
            <li className="pp_li">
              To facilitate and provide various services on the Platform
              including to enable you to create your goals for learning/
              playlists of Content based on your own interest and share it with
              other Users, who may have the same interest;
            </li>
            <li className="pp_li">
              To enable you to discover new Content linked to any other Content
              that you are viewing;
            </li>
            <li className="pp_li">
              To provide personalised recommendation based on your past visits
              and activities on the Platform;
            </li>
            <li className="pp_li">For authentication purposes;</li>
            <li className="pp_li">
              To provide push notifications on your hand-held devices (mobile,
              tablets, etc.) of any comment / feedback received on any Content
              you have contributed, from other Users, only if you have provided
              permission for the same during the installation of the application
              on such device;
            </li>
            <li className="pp_li">
              To provide you an option to share Content through your social
              media accounts;
            </li>
            <li className="pp_li">
              To allow you to post blogs / questions, reply / comment on
              blogs/questions and vote on blogs / questions / responses provided
              to questions;
            </li>
            <li className="pp_li">
              To list initiatives for Users to reach out in case of questions /
              concerns on the initiative;
            </li>
            <li className="pp_li">
              To enable communication between Users through the Platform;
            </li>
            <li className="pp_li">To track efficient usage of Content; </li>
            <li className="pp_li">
              To resolve disputes, troubleshoot problems, measure interest in
              the services provided on the Platform, detect and protect you and
              us against error, fraud and other criminal activity, enforce the
              Terms of Use or other agreements between us and as otherwise
              described to you at the time of collection;
            </li>
            <li className="pp_li">
              To contact you and deliver information to your with respect to and
              relevant to your usage of the Platform;{" "}
            </li>
          </ol>
        </li>
        <li className="pp_li">
          <b>Your Rights (as per applicable data protection laws):</b> You are
          entitled at any time to access, rectify or delete your Personal Data,
          as well as to object for legitimate purpose to the processing of your
          Personal Data. In addition, you have the right to receive Personal
          Data concerning you which you have provided to us directly. These
          rights may be exercised, by sending an email to
          space@societalplatform.org.
        </li>
        <b>Secondary Purpose:</b> If we wish to use your personal data for a new
        purpose, not covered by this notice, then we will provide you with a new
        notice explaining this new use prior to commencing the processing and
        setting out the relevant purposes and processing conditions. Where and
        whenever necessary, we will seek your prior consent to the new
        processing, unless authorized by law.
        <li className="pp_li">
          <b>Data Transfer:</b>
          <ol type="a">
            <li className="pp_li">
              For Non-EU: If necessary for the above stated purpose, the
              Personal Data listed above will be transferred to our offices
              located in India and to our authorized service providers cloud
              servers.
            </li>
            <li className="pp_li">
              For EU: The Personal Data listed above may be transferred to
              countries located outside of the EU/EEA, for which data protection
              legislation may differ from that of the EEA, i.e. our offices
              located in India and our third party providers cloud servers. We
              shall take the necessary steps to ensure confidentiality and
              security of the transferred data and have also implemented
              adequate safeguards for data transfer the copies can be obtained
              by writing to space@societalplatform.org.
            </li>
          </ol>
        </li>
        <li className="pp_li">
          <b>Data Security:</b> EkStep adopts reasonable and appropriate
          security practices and procedures in accordance with Indian laws
          currently in force, including the Information Technology (Reasonable
          Security Practices and Procedures and Sensitive Personal Data or
          Information) Rules, 2011, including in relation to administrative,
          physical security, and technical controls in order to safeguard your
          Personal Data.
        </li>
        <li className="pp_li">
          <b>Data Retention:</b> Personal Data will not be retained for a period
          more than necessary to fulfill the purposes outlined in this notice,
          unless a longer retention period is required by law or for directly
          related legitimate business purposes.
        </li>
        <li className="pp_li">
          <b>
            Contact your Local Data Protection Authority (For EU Data Subjects):
          </b>{" "}
          If you are unhappy with how we safeguard your personal data, you have
          the right to bring a complaint to your local data protection
          authority.
        </li>
        <li className="pp_li">
          <b>Contact your Data Protection Officer:</b> If you have any queries,
          comments or requests, please contact our data protection officer via
          email at space@societalplatform.org.
        </li>
        <li className="pp_li">
          <b>Please note:</b>
          <ol type="a">
            <li className="pp_li">
              While uploading any video, or providing your feedback to the
              author of the Content or maker of the video as a User, please do
              not include any personal or sensitive personal information
              concerning others.
            </li>
            <li className="pp_li">
              The Platform may provide links to third party websites, platforms,
              or applications, which may collect information about you. Please
              read their policies carefully, before providing any information.
              You are advised to take adequate measures to protect your privacy.
              EkStep will not be responsible or liable for the privacy
              standards, practices and policies implemented on such websites,
              platforms, or applications.
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};

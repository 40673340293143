import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Dropdown } from "react-bootstrap";

import { CardResult } from "../ui/cardResult/cardresult";

import { AiOutlineSearch, AiOutlineArrowUp } from "react-icons/ai";

import { searchResult, teleEvent } from "../../apis";

import InfiniteScroll from "react-infinite-scroll-component";

import "./result.css";
const ResultComp = ({ setModalTitle, setModalShow }) => {
  const navigator = useNavigate();
  const [params] = useSearchParams();
  const [resResult, setResResult] = useState("");
  const [newResult, setNewResult] = useState({});

  const [colResult, setColResult] = useState("");
  const [newColResult, setNewColResult] = useState({});

  const [resCount, setResCount] = useState(0);
  const [colCount, setColCount] = useState(0);
  const [searchInput, setSearchInput] = useState(params.get("term"));
  const [resCont, setResCont] = useState(localStorage.getItem("view"));

  const [currFilter, setCurrFilter] = useState(
    localStorage.getItem("currFilter")
  );
  const [rellFilter, setRelFilter] = useState(
    localStorage.getItem("rellFilter")
  );
  const [catFilter, setCatFilter] = useState(localStorage.getItem("catFilter"));

  const [pag, setPag] = useState(10);
  const [colPag, setColPag] = useState(10);

  useEffect(() => {
    localStorage.setItem("view", resCont);
  }, [resCont]);

  const filterFunction = (arr) => {
    if (currFilter === "Read") {
      if (["read", "Read"].includes(resResult[arr].type)) {
        return arr;
      }
    } else if (currFilter === "Listen") {
      if (["listen", "Listen"].includes(resResult[arr].type)) {
        return arr;
      }
    } else if (currFilter === "Watch") {
      if (["watch", "Watch"].includes(resResult[arr].type)) {
        return arr;
      }
    } else {
      return arr;
    }
  };

  const catfilterFunction = (arr) => {
    if (catFilter === "Method") {
      if (["Method", "method"].includes(resResult[arr].catalog)) {
        return arr;
      }
    } else if (catFilter === "Experiences") {
      if (
        ["Experience", "experience", "experiences", "Experiences"].includes(
          resResult[arr].catalog
        )
      ) {
        return arr;
      }
    } else if (catFilter === "Technology") {
      if (["Technology", "technology"].includes(resResult[arr].catalog)) {
        return arr;
      }
    } else {
      return arr;
    }
  };

  const sortFunction = (a, b) => {
    if (rellFilter === "Most Viewed") {
      let start = resResult[a].views === undefined ? 0 : resResult[a].views;
      let end = resResult[b].views === undefined ? 0 : resResult[b].views;
      return end - start;
    }
    if (rellFilter === "Latest") {
      return (
        new Date(resResult[b].publishedDate) -
        new Date(resResult[a].publishedDate)
      );
    }
  };

  const handleSeachButton = (event) => {
    localStorage.setItem("currFilter", "All");
    localStorage.setItem("rellFilter", "Relevance");
    localStorage.setItem("catFilter", "All");
    event.preventDefault();
    if (event.key === "Enter") {
      navigator(
        `/result?term=${encodeURIComponent(
          searchInput
        )}&type=Organic&location=SearchResultPage`
      );
    }
    navigator(
      `/result?term=${encodeURIComponent(
        searchInput
      )}&type=Organic&location=SearchResultPage`
    );
  };

  const handleClickRes = (id) => {
    navigator(`/resview?id=${id}&location=SearchResultPage`);
  };

  const handleClickCol = (id) => {
    navigator(`/colview?id=${id}&location=CollViewPage`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setCurrFilter(localStorage.getItem("currFilter"));
    setRelFilter(localStorage.getItem("rellFilter"));
    setCatFilter(localStorage.getItem("catFilter"));
    setSearchInput(params.get("term"));
    searchResult(
      localStorage.getItem("accessToken"),
      params.get("term"),
      params.get("term") === "" ? true : false,
      params.get("type"),
      params.get("location")
    )
      .then((res) => {
        setResResult(res.data.response.result.resources.data);
        setResCount(res.data.response.result.resources.count);
        setColResult(res.data.response.result.bundles.data);
        setColCount(res.data.response.result.bundles.count);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setResResult([]);
          setColResult([]);
        }
        if (err.response.data.response.params.errmsg === "Invalid Token") {
          localStorage.clear();
          setModalShow(true);
          setModalTitle("Please update your profile.");
          navigator("/");
        }
      });
    localStorage.setItem("prevLocation", "SearchResultPage");
  }, [params.get("term")]);

  useEffect(() => {
    localStorage.setItem("currFilter", currFilter);
    localStorage.setItem("rellFilter", rellFilter);
    localStorage.setItem("catFilter", catFilter);
    let unsortedArr = Object.keys(resResult)
      .filter(filterFunction)
      .filter(catfilterFunction)
      .sort((a, b) => sortFunction(a, b));
    setResCount(unsortedArr.length);
    let sortedArr = unsortedArr.splice(0, pag);
    setNewResult(sortedArr);
  }, [
    currFilter,
    rellFilter,
    newResult.length,
    resResult.length,
    catFilter,
    pag,
  ]);

  useEffect(() => {
    let arr = Object.keys(colResult).splice(0, colPag);
    setNewColResult(arr);
  }, [colPag, colResult.length, newColResult.length]);

  return (
    <div className="result-comp_body">
      <form onSubmit={handleSeachButton}>
        <div className="result-comp_search-wrapper">
          <div className="input-group result-comp_search-input me-2">
            <button
              className="input-group-text search-comp_icon p-2"
              type="submit"
              onClick={(event) => handleSeachButton(event)}
            >
              <AiOutlineSearch className="result-comp_icon-icon" />
            </button>
            <input
              type="text"
              className="form-control search-comp_input p-2"
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
              required
            />
          </div>
        </div>
      </form>
      <div className="search-comp_rb-container">
        <div
          className={
            resCont !== "res"
              ? "search-comp_rb-button search-comp_rb-button-active"
              : "search-comp_rb-button"
          }
          onClick={() => {
            setResCont("res");
            teleEvent(
              localStorage.getItem("accessToken"),
              "resource",
              "SearchResultPage"
            );
          }}
        >
          Resources ({resCount})
        </div>
        <div
          className={
            resCont !== "coll"
              ? "search-comp_rb-button search-comp_rb-button-active"
              : "search-comp_rb-button"
          }
          onClick={() => {
            setResCont("coll");
            teleEvent(
              localStorage.getItem("accessToken"),
              "collection",
              "SearchResultPage"
            );
          }}
        >
          Collections ({colCount})
        </div>
        <div className="result-comp_bg"> </div>
      </div>

      {/* sort dropdown */}
      {resCont == "res" && (
        <div className="result_comp--sort ">
          <Dropdown>
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              className="btn-custom btn-sort"
            >
              Sort by | {rellFilter} <span className="dropdown-icon-v"></span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  setRelFilter("Relevance");
                  teleEvent(
                    localStorage.getItem("accessToken"),
                    "sort",
                    "SearchResultPage",
                    "relevance"
                  );
                }}
              >
                Relevance
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setRelFilter("Most Viewed");
                  teleEvent(
                    localStorage.getItem("accessToken"),
                    "sort",
                    "SearchResultPage",
                    "mostViewed"
                  );
                }}
              >
                Most Viewed
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setRelFilter("Latest");
                  teleEvent(
                    localStorage.getItem("accessToken"),
                    "sort",
                    "SearchResultPage",
                    "latest"
                  );
                }}
              >
                Latest
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown className="filter-cont">
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              className="btn-custom btn-sort"
            >
              Format | {currFilter} <span className="dropdown-icon-v"></span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  setCurrFilter("All");
                  teleEvent(
                    localStorage.getItem("accessToken"),
                    "format",
                    "SearchResultPage",
                    "all"
                  );
                }}
              >
                All
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setCurrFilter("Read");
                  teleEvent(
                    localStorage.getItem("accessToken"),
                    "format",
                    "SearchResultPage",
                    "read"
                  );
                }}
              >
                Read
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setCurrFilter("Watch");
                  teleEvent(
                    localStorage.getItem("accessToken"),
                    "format",
                    "SearchResultPage",
                    "watch"
                  );
                }}
              >
                Watch
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setCurrFilter("Listen");
                  teleEvent(
                    localStorage.getItem("accessToken"),
                    "format",
                    "SearchResultPage",
                    "listen"
                  );
                }}
              >
                Listen
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown className="filter-cont">
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              className="btn-custom btn-sort"
            >
              Catalog | {catFilter} <span className="dropdown-icon-v"></span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  setCatFilter("All");
                  teleEvent(
                    localStorage.getItem("accessToken"),
                    "catalog",
                    "SearchResultPage",
                    "all"
                  );
                }}
              >
                All
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setCatFilter("Method");
                  teleEvent(
                    localStorage.getItem("accessToken"),
                    "catalog",
                    "SearchResultPage",
                    "method"
                  );
                }}
              >
                Method
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setCatFilter("Experiences");
                  teleEvent(
                    localStorage.getItem("accessToken"),
                    "catalog",
                    "SearchResultPage",
                    "experiences"
                  );
                }}
              >
                Experiences
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setCatFilter("Technology");
                  teleEvent(
                    localStorage.getItem("accessToken"),
                    "catalog",
                    "SearchResultPage",
                    "technology"
                  );
                }}
              >
                Technology
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
      {/*  sort dropdown end */}

      <div className="result-comp_wrapper">
        {resCont === "res" ? (
          <ResCompRes
            result={resResult}
            handleClick={handleClickRes}
            newResult={newResult}
            searchInput={searchInput}
            setPag={setPag}
            pag={pag}
          />
        ) : (
          <ResCompCol
            newResult={newColResult}
            result={colResult}
            handleClick={handleClickCol}
            resCont={resCont}
            searchInput={searchInput}
            setColPag={setColPag}
            pag={colPag}
          />
        )}
      </div>
    </div>
  );
};

export { ResultComp };

const ResCompRes = ({
  newResult,
  result,
  handleClick,
  searchInput,
  setPag,
  pag,
}) => {
  if (result === "") {
    return (
      <div className="searc-comp_spinner-cont">
        <div className="spinner-border" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
    );
  } else if (newResult.length === 0) {
    return (
      <div className="result-comp_res-no-res">
        <p>Sorry! We could not find any results for '{searchInput}'.</p>

        <ul>
          <b>Try modifying your search. Here are some ideas:</b>
          <li className="ms-3">Make sure all words are spelled correctly</li>
          <li className="ms-3">Try different search terms</li>
          <li className="ms-3">Try more general search terms</li>
        </ul>
      </div>
    );
  }

  return (
    <>
      <InfiniteScroll
        dataLength={newResult.length}
        next={() => {
          setPag((prev) => prev + 10);
        }}
        hasMore={true}
      ></InfiniteScroll>
      {newResult.map((x) => {
        return (
          <>
            <CardResult
              id={result[x]?._id}
              docTitle={result[x]?.docTitle}
              type={result[x]?.type}
              docDesc={result[x]?.docDesc}
              keywords={result[x]?.keywords}
              handleClick={handleClick}
            />
          </>
        );
      })}
      {pag >= newResult.length ? (
        <span className="load-success">
          <span className="me-2">No more results found</span>
          {result.length >= 10 ? (
            <>
              |
              <span
                className="ms-2 mouse-cursor"
                onClick={() => window.scrollTo(0, 0)}
              >
                Scroll to the top <AiOutlineArrowUp />
              </span>
            </>
          ) : (
            ""
          )}
        </span>
      ) : (
        <button
          className="load-more-button"
          onClick={() => setPag((prev) => prev + 10)}
        >
          Load more
        </button>
      )}
    </>
  );
};
const ResCompCol = ({
  result,
  handleClick,
  searchInput,
  newResult,
  setColPag,
  pag,
}) => {
  if (result === "") {
    return (
      <div className="searc-comp_spinner-cont">
        <div className="spinner-border" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
    );
  } else if (newResult.length === 0) {
    return (
      <div className="result-comp_res-no-res">
        <p>Sorry! We could not find any results for '{searchInput}'.</p>

        <ul>
          <b>Try modifying your search. Here are some ideas:</b>
          <li className="ms-3">Make sure all words are spelled correctly</li>
          <li className="ms-3">Try different search terms</li>
          <li className="ms-3">Try more general search terms</li>
        </ul>
      </div>
    );
  }
  return (
    <>
      <InfiniteScroll
        dataLength={newResult.length}
        next={() => {
          setColPag((prev) => prev + 10);
        }}
        hasMore={true}
      ></InfiniteScroll>
      {newResult.map((x) => {
        return (
          <>
            {result[x] && (
              <CardResult
                id={result[x]?._id}
                docTitle={result[x]?.bundleTitle}
                type={result[x]?.type}
                docDesc={result[x]?.description}
                keywords={result[x]?.keywords}
                handleClick={handleClick}
              />
            )}
          </>
        );
      })}
      {pag >= newResult.length ? (
        <span className="load-success">
          <span className="me-2">No more results found</span>
          {result.length >= 10 ? (
            <>
              |
              <span
                className="ms-2 mouse-cursor"
                onClick={() => window.scrollTo(0, 0)}
              >
                Scroll to the top <AiOutlineArrowUp />
              </span>
            </>
          ) : (
            ""
          )}
        </span>
      ) : (
        <button
          className="load-more-button"
          onClick={() => setColPag((prev) => prev + 10)}
        >
          Load more
        </button>
      )}
    </>
  );
};

import React from "react";

const DividerLine = () => {
  return (
    <div>
      <div className="text-center divider-line">
        <span>or</span>
      </div>
    </div>
  );
};

export {DividerLine};

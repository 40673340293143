import "./tnc.css";
import { useEffect } from "react";
export const TncComp = () => {
  useEffect(() => {
    document.querySelector('body').scrollTo(0,0)
  }, []);
  return (
    <div className="tnc-comp">
      <center>
        <b>Terms of Use</b>
      </center>

      <p>
        Effective Date: 15<sup>th</sup> July, 2020
      </p>

      <p>
        The SPaceplatform available on https://space.societalthinking.org/
        (“Platform”) has been developed and is being maintained by EkStep
        Foundation (“EkStep”). The Platform has been developed with a purpose to
        enable interactions among practitioners of Societal Thinking including
        but not limited to people from societal organisations, consulting
        organisations, academia. (“Purpose”).
      </p>

      <p>
        EkStep is a not-for-profit company registered under the Indian Companies
        Act 2013, having its office at No. 85, 7th Cross, Koramangala 4th Block,
        Bangalore 560034.
      </p>

      <p>
        The terms ‘you’, ‘your’ refer to anyone who accesses, views, uses or
        contributes (“Use(s)”) to the Platform (“User”). The terms "we", "us",
        "our" refer to EkStep. These terms of use, as amended, (“Terms”) govern
        the usage of the Platform by its Users.
      </p>

      <p>
        By using the Platform, you have accepted and agree to be governed by
        these Terms, as may be amended from time to time.
      </p>

      <ol type="1">
        <li className="pp_li">
          <b>Definitions </b>
          <ol type="a">
            <li className="pp_li">
              <b>Content</b> means and includes content, resources, assets,
              courses, feedback and postings on the Platform expressed as text,
              documents, presentations, scripts, graphics, images,
              illustrations, photos, sounds, music, videos, audiovisual
              combinations, codebase/code, documentation, APIs, sandbox, links
              to external web pages or other such mediums of expression and
              other materials you may view on, access through or contribute to
              the Platform.
            </li>
            <li className="pp_li">
              <b>Platform</b> means the SPace platform, an initiative of EkStep
              Foundation, which is accessible online at its web domain
              https://space.societalplatform.org/ and through its mobile
              applications.
            </li>
            <li className="pp_li">
              <b>Use / Usage / Using</b> means and refers to using, accessing,
              viewing or contributing Content on the Platform.
            </li>
            <li className="pp_li">
              <b>User</b> means and refers to all the users of the Platform, who
              can access the Platform and use the Content on the Platform,
              including but not limited to User who are authorised to be content
              creators, reviewers or publishers.
            </li>
          </ol>
        </li>

        <li className="pp_li">Access & Use of the Platform</li>
        <ol type="a">
          <li className="pp_li">
            All Users need to register with the Foundation to Use the Platform.
          </li>
          <li className="pp_li">
            All Users are expected to be 18 year or above. As a User, you
            represent and warrant that you are of legal age and are legally
            competent to form a binding contract. If you’re agreeing to these
            Terms on behalf of an institution, organization or legal entity, you
            represent and warrant that you are duly authorized to agree to these
            Terms on behalf of that institution, organization or entity and
            these Terms are binding on them.
          </li>
          <li className="pp_li">
            We reserve the right to refuse or terminate registration if it is
            brought to our notice that you are below 18 years of age.
          </li>
          <li className="pp_li">Users can:</li>
          <ol type="i">
            <li className="pp_li">
              Access and use Content available on the Platform;
            </li>
            <li className="pp_li">Create and manage their User Profiles; </li>
            <li className="pp_li">Communicate with other Users; and</li>
            <li className="pp_li">Share Content with other Users.</li>
          </ol>

          <li className="pp_li">
            Certain Users may have additional authorisations to create, curate,
            review and publish Content on the Platform and accordingly may have
            additional permissions and usage rights on the Platform that enables
            them to contribute Content on the Platform. Such rights and
            authorisations shall be given by the Foundation, at its sole
            discretion.
          </li>
          <li className="pp_li">
            Users are responsible for maintaining the confidentiality of their
            User ID and password and all activities that occur under their User
            ID and password. Any unauthorised use of your User ID and password
            or any breach of security must be notified by sending any email
            notification to space@societalplatform.org. Users agree to provide
            true, accurate, current and complete information about themselves.
          </li>
          <li className="pp_li">
            Users shall not transfer their rights to access the Platform to
            another person unless they have obtained explicit consent from
            EkStep in writing to do so.
          </li>
          <li className="pp_li">
            All Users shall follow the extant policies and guidelines of the
            Platform at all times while Using the Platform.
          </li>
          <li className="pp_li">
            As a User, you will be responsible for all your actions and
            activities in relation to your Usage of the Platform.
          </li>
          <li className="pp_li">
            Your Use of the Platform may possibly be disrupted due to technical
            or operational difficulties, without prior notice of downtime.
            EkStep makes no guarantee as to the continuous uptime and
            availability of the Platform or the quality of Content on the
            Platform.
          </li>
          <li className="pp_li">
            Subject to the terms and conditions set forth in these Terms of Use,
            EkStep grants the User a non-exclusive, non-transferable,
            non-commercial, limited right to access the Platform and the Content
            therein. Users shall access the Content on the Platform only for
            their own personal use.
          </li>
        </ol>

        <li className="pp_li">
          <b>Content Policy & Community Guidelines</b>
          <ol type="a">
            <li className="pp_li">
              When Users Use the Platform, you join a community of practitioners
              of Societal Platform Idea including but not limited to people from
              societal organisations, consulting organisations, academia which
              involves a certain level of trust.
            </li>
            <li className="pp_li">
              Users are expected to respect that trust and be responsible about
              their Usage of the Platform and all its Content and follow the
              Content Policy & Community Guidelines.
            </li>
            <li className="pp_li">
              By submitting / creating / curating / publishing Content on the
              Platform, Users recognise and accept that the Content will be Used
              by other Users of the Platform.
            </li>
            <li className="pp_li">
              Users shall ensure that proper and correct attributions,
              acknowledgments and sourcing references are given to the Content
              and individual(s)/ institution(s) that have been involved in the
              development and creation of Content and wherever Content has been
              quoted/used.
            </li>
            <li className="pp_li">
              Users shall ensure that they do not infringe on any third party
              rights, including but not limited to the intellectual property
              rights such as copyrights, trademarks with regard to Content on
              the Platform.
            </li>
            <li className="pp_li">
              For any legal matter arising out of infringement of any third
              party rights by the User, such User shall be solely responsible
              for any financial or other damages arising out of such violations
              and disputes.
            </li>
            <li className="pp_li">
              EkStep reserves the right to not publish on or remove from the
              Platform any Content provided by a User.
            </li>
          </ol>
        </li>

        <li className="pp_li">
          <b>Prohibited Content & Use: Users shall not:</b>
          <ol type="a">
            <li className="pp_li">
              Use the Platform for any purpose other than the Purpose;
            </li>
            <li className="pp_li">
              Post, upload or distribute any Content that does not comply with
              the Content Policy & Community Guidelines;
            </li>
            <li className="pp_li">
              Impersonate any person or entity, falsely claim an affiliation
              with any person or entity, or access Platform accounts of others
              without permission, or perform any fraudulent activity on the
              Platform;
            </li>
            <li className="pp_li">
              Delete the copyright or other proprietary rights notices on the
              Platform or any licensed Content;
            </li>
            <li className="pp_li">Send junk mail or spam to other Users;</li>
            <li className="pp_li">
              Use the Platform for any illegal purposes, or in violation of any
              local, state, national or international law, including without
              limitation, laws governing intellectual property and other
              proprietary rights, data protection and privacy right;
            </li>
            <li className="pp_li">
              Defame, harass, abuse, threaten or defraud Users, or collect or
              attempt to collect personal information or sensitive personal
              information or data about Users or third parties, without their
              consent;
            </li>
            <li className="pp_li">
              Remove, circumvent, disable, damage, or otherwise interfere with
              security related features of the Platform;
            </li>
            <li className="pp_li">
              Modify, adapt, translate or create derivative works based on
              Content on the Platform, or any part thereof, except and only to
              the extent expressly permitted by the license applicable to such
              Content; or
            </li>
            <li className="pp_li">
              Intentionally interfere with or damage the operation of the
              Platform or any User’s Usage of it, by any means, including
              without limitation by participation in any denial-of-service type
              attacks or by uploading or otherwise disseminating viruses,
              adware, spyware, worms, or other malicious code.
            </li>
          </ol>
        </li>

        <li className="pp_li">
          <b>Intellectual Property Rights</b>
          <ol type="a">
            <li className="pp_li">
              The Platform contains copyrighted material, trademarks and other
              intellectual property owned by the Foundation or the Users.
            </li>
            <li className="pp_li">
              You agree not to copy, modify, publish, transmit, distribute,
              perform, display or sell any such intellectual property.
            </li>
            <li className="pp_li">
              Access to or Use of the Platform does not confer upon anyone any
              license to EkStep’s or any User’s intellectual property rights.
            </li>
            <li className="pp_li">
              You agree that you will not Use the Platform to scrape or attempt
              to scrape any Content available on the Platform.
            </li>
          </ol>
        </li>

        <li className="pp_li">
          <b>User Information & Privacy </b>
          <ol type="a">
            <li className="pp_li">
              EkStep takes the privacy of its Users very seriously. Please
              review the entire Privacy Policy.
            </li>
            <li className="pp_li">
              By using the Platform and / or by providing your information, you
              consent to the collection and use of your information on the
              Platform, in accordance with the Privacy Policy.
            </li>
            <li className="pp_li">
              Users shall not collect any personal information or sensitive
              personal data of other Users through the Platform, but if you do
              collect such information, you must ensure that such collection,
              storage, transfer and disclosure is in accordance with Indian law
              currently in force, including but not limited to the (Indian)
              Information Technology (Reasonable Security Practices and
              Procedures) Rules, 2011. EkStep shall not be responsible for any
              such information collected by its Users.
            </li>
          </ol>
        </li>

        <li className="pp_li">
          <b>External Links: </b>Links of other websites which are accessible
          from any Content on the Platform, may be governed by terms and
          conditions different from these Terms. Users shall check the relevant
          terms and conditions of those other websites before using any content
          available on those websites.
        </li>

        <li className="pp_li">
          <b>Changes in Policies:</b> These Terms (including any other Platform
          policies), may be updated or modified from time to time and the
          revised Terms will be reflected herein. Your continued use of the
          Platform constitutes acceptance of the then-current Terms. Hence, we
          encourage you to visit this page periodically to review any changes.
          We will post an effective date at the top of this page to make it
          clear when we made our most recent update.
        </li>

        <li className="pp_li">
          <b>Limited Liability</b>
          <ol type="a">
            <li className="pp_li">
              EkStep does not guarantee the accuracy, veracity, correctness,
              validity, usability, currency of any of the Content made available
              on the Platform or take any responsibility for any external
              websites linked on the Platform.
            </li>
            <li className="pp_li">
              Users are responsible for all the Content posted, transmitted,
              sent or communicated by them through the Platform. EkStep shall
              not be held responsible for any Content that is offensive,
              unlawful or does not otherwise comply with Content Policy &
              Community Guidelines.
            </li>
            <li className="pp_li">
              When you select a link to an outside website, you are leaving the
              Platform and are subject to the privacy and security policies of
              the owners/sponsors of the outside website / domain.
            </li>
          </ol>
        </li>

        <li className="pp_li">
          <b>Indemnity:</b> You agree to fully indemnify and hold EkStep, its
          directors, officers, employees and agent, harmless for any liability,
          claim, proceedings, penalties, damages, losses, costs and expenses
          that comes as a result of your Usage of the Platform, your breach of
          the Terms, violation of any law, rules, regulations in relation to
          your use of the Platform.
        </li>

        <li className="pp_li">
          <b>Infringement: </b> If you think that something on the Platform
          infringes your right to privacy or intellectual property rights,
          including copyright or trademark rights, please contact us on
          space@societalplatform.org with details of the alleged infringement
          and your contact details.
        </li>

        <li className="pp_li">
          <b>Termination:</b> Any violation or breach of these Terms, including
          while investigating complaints or alleged violation of these Terms,
          may lead to suspension or termination of your registration and access
          to the Platform. If EkStep decides to suspend or end your access, we
          will try to notify you using your email address.
        </li>

        <li className="pp_li">
          <b>Electronic Agreement:</b> This document is a written agreement and
          an electronic record and valid and enforceable electronic agreement /
          contract under Information Technology Act, 2000 (as applicable in
          Republic of India) and rules there under as applicable and the amended
          provisions pertaining to electronic records in various statutes under
          applicable Indian laws. This electronic record is generated by a
          computer system and does not require any physical or digital
          signatures. Your usage of the Platform shall be your deemed acceptance
          of these Terms and all the modifications and updates thereto.
        </li>

        <li className="pp_li">
          <b>Governing Law:</b> These Terms shall be governed by and construed
          in accordance with the Indian law. Any dispute arising under these
          Terms shall be subject to the exclusive jurisdiction of the courts of
          Bangalore, India.
        </li>

        <li className="pp_li">
          <b>Disclaimers:</b> THE PLATFORM, INCLUDING THE CONTENT, IS AVAILABLE
          ON AN “AS-IS” BASIS AND THERE ARE NO WARRANTIES OR LEGAL GUARANTEES OF
          ANY KIND SUCH AS “MERCHANTABILITY”, “FITNESS FOR A PARTICULAR
          PURPOSE”, “NON-INFRINGEMENT”. USE OF THE PLATFORM (INCLUDING ANY
          CONTENT OR INFORMATION AVAILABLE ON/THROUGH THE PLATFORM) IS ENTIRELY
          AT YOUR OWN RISK. BY USING THE PLATFORM, YOU AGREE THAT EKSTEP WILL
          NOT BE LIABLE IN ANY WAY FOR ANY INABILITY TO USE THE PLATFORM, OR FOR
          ANY CLAIM ARISING OUT OF THESE TERMS. EKSTEP SPECIFICALLY DISCLAIMS
          THE FOLLOWING: INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR
          EXEMPLARY DAMAGES, DIRECT OR INDIRECT DAMAGES FOR LOSS OF GOODWILL,
          WORK STOPPAGE, LOST PROFITS, LOSS OF DATA OR COMPUTER MALFUNCTION
          ARISING OUT OF OR IN CONNECTION WITH THE ACCESS AND USE OF THE
          PLATFORM.
        </li>
      </ol>
    </div>
  );
};

import { Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./cardresult.css";

const CardResult = ({ docTitle, docDesc, id, handleClick, type, keywords }) => {
  const navigator = useNavigate();
  return (
    <div className="card card-comp" key={id}>
      <div className="card-body">
        <div className="card-body_content">
          <b className="card-title_container">
            <h5
              className="card-title mouse-cursor"
              onClick={() => handleClick(id)}
            >
              {docTitle}
              <Badge className="card-comp_bagde me-2">{type}</Badge>
            </h5>
          </b>
          <div className="card-desc">{docDesc}</div>

          <div className="card--keywords">
            <div className="card--keywords--keywords">
              {keywords === undefined ? "" : "#"}
              {keywords?.length !== 0
                ? keywords?.map((i, x) => (
                    <span
                      className="m-1 card_custom-pills"
                      onClick={() => {
                        navigator(`/result?term=${i}&type=Guided&location=SearchResultPage`)
                        localStorage.setItem("currFilter", "All");
                        localStorage.setItem("rellFilter", "Relevance");
                        localStorage.setItem("catFilter", "All");
                      }}
                    >
                      {keywords.length - 1 === x ? (
                        <span>{i}</span>
                      ) : (
                        <span className="pipeline-keywords"> {i} </span>
                      )}
                    </span>
                  ))
                : ""}
            </div>
            <div className="card-body_button">
              <button
                className="btn btn-custom card-body_button--button"
                onClick={() => handleClick(id)}
              >
                View
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CardResult };

import "./videoViewer.css"
import React from 'react'
import ReactPlayer from 'react-player/lazy'


export const VideoViewer= ({url}) => {
    return (
        <div className="video-player-wrapper">
            <ReactPlayer 
                className='react-player'
                url={url}
                controls={true}
                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                onContextMenu={e => e.preventDefault()}        
        />
        </div>
    )
}
import axios from "axios";

// const baseUrl = "https://8cd2-106-51-129-143.in.ngrok.io"

const baseUrl = "https://space.societalthinking.org/api"

// __ signin __
export const signInwithEmailAndPAssword = async (
  email,
  password,
  rememberMe
) => {
  let message = {
    request: {
      data: {
        email: email,
        password: password,
        rememberMe: rememberMe,
      },
    },
  };
  return await axios.post(`${baseUrl}/user/v1/login/`, message);
};

export const forgotPassword = async (email) => {
  let message = {
    request: {
      data: {
        email: email,
      },
    },
  };

  return axios.post(`${baseUrl}/user/v1/reset/password/`, message);
};

export const signInasGuest = () => {
  return axios.get(`${baseUrl}/user/v1/guest/login`);
};

// --signup--

export const checkIfEmailExists = async (email) => {
  let message = {
    request: {
      data: {
        email: email,
      },
    },
  };
  return axios.post(`${baseUrl}/user/v1/mail/exists`, message);
};

export const signUp = async (fname, lname, email) => {
  let message = {
    request: {
      data: {
        firstName: fname,
        lastName: lname,
        email: email,
      },
    },
  };

  return axios.post(`${baseUrl}/user/v1/signup/`, message);
};

// --activation page--

export const verifyToken = async (param) => {
  let message = {
    request: {
      data: {
        token: param,
      },
    },
  };
  return axios.post(`${baseUrl}/user/v1/verify/`, message);
};

export const setPaswword = async (param, password) => {
  let message = {
    request: {
      data: {
        token: param,
        password: password,
      },
    },
  };

  return axios.post(`${baseUrl}/user/v1/update/password`, message);
};

// --update profile--
export const updateUser = async (
  fname,
  lname,
  org,
  country,
  role,
  domainList,
  expertiseList,
  profileLink,
  emailConsent,
  imageUrl
) => {
  let message = {
    request: {
      data: {
        firstName: fname,
        lastName: lname,
        organization: org,
        country: country,
        role: role,
        domain: domainList,
        areaOfExpertise: expertiseList,
        profileLink: profileLink,
        newsLetterSubscribed: emailConsent,
        imageUrl: imageUrl,
      },
    },
  };
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };

  return await axios.post(`${baseUrl}/user/v1/update/profile`, message, {
    headers,
  });
};

export const getUserDetails = async (accessToken) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  return axios.get(`${baseUrl}/user/v1/getuser/`, { headers });
};

export const getUploadUrl = async (filename) => {
  const headers = {
    Authorization: `Bearer  ${localStorage.getItem("accessToken")}`,
  };

  let message = {
    request: {
      data: {
        fileName: filename,
      },
    },
  };

  return axios.post(`${baseUrl}/user/v1/getUploadUrl`, message, { headers });
};

export const uploadToGoogle = (url, filePath, ext) => {
  const headers = {
    "Content-Type": `image/${ext}`,
  };
  return axios.put(url, filePath, { headers });
};

// --search--

export const searchResult = async (accessToken, term, all, type, location) => {
  let message = {
    request: {
      data: {
        query: term,
        all: all,
        type: type,
        clickLocation: location
      },
    },
  };
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return axios.post(`${baseUrl}/user/v1/content/search/`, message, { headers });
};

export const getCommonSearchTerms = async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  return axios.get(`${baseUrl}/common/v1/content/searchKeywords`, { headers });
};

export const getQuestions = async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  return axios.get(`${baseUrl}/admin/v1/question`, { headers });
};

export const latestRes = async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  return axios.get(`${baseUrl}/common/v1/content/latestFeeds`, { headers });
};
// [
  // "Homepage",
  // "SearchResultPage",
  // "ResViewPage",
  // "CollViewPage",
// ]
// --view--
export const getIndividualRes = async (accessToken, id, location) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return axios.get(`${baseUrl}/user/v1/content/viewIndividual/${id}/${location}`, {
    headers,
  });
};

export const getBundleRes = async (accessToken, id) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return axios.get(`${baseUrl}/common/v1/bundle/${id}`, { headers });
};

export const downloadCounter = (accessToken, id, location) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const message = {
    request: {
      data: {
        action: "updateDownloadCount",
        resourceId: id,
        clickLocation: location
      },
    },
  };

  return axios.post(`${baseUrl}/user/v1/event/`, message, { headers });
};

export const likeCounter = (accessToken, id, location) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  return axios.get(`${baseUrl}/user/v1/content/like/${id}/${location}`, { headers });
};

export const colLikeCounter = (accessToken, id, location) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  return axios.get(`${baseUrl}/user/v1/bundle/like/${id}/${location}`, { headers });

}

//contact us
export const contactUs = (name, email, mess) => {
  const message = {
    request: {
      data: {
        name: name,
        email: email,
        message: mess,
      },
    },
  };

  return axios.post(`${baseUrl}/user/v1/contact/`, message);
};


// telemetry 
export const teleEvent = (token, event, location, subEvent) => {
  const message = {
    request: {
      data: {
        accessToken: token === null ? "" : token,
        event : event,
        clickLocation: location,
        subEvent: subEvent === null ? "" : subEvent,
      },
    },
  };

  return axios.post(`${baseUrl}/common/v1/event/tele`, message);
};

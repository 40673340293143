import { PdfModalViewer } from "./pdfViewer/pdfViewer";
import { OfficeViewer } from "./OfficeViewer/officeViewer";
import { TextViewer } from "./textViewer/textViewer";
import { VideoViewer } from "./videoViewer/videoViewer";
import { AudioViewer } from "./audioPlayer/audioplayer";
import { NoLink } from "./NoLink/Nolinl";

export const ViewController = ({ type, url, attachment, filename }) => {
  if (type === ".pdf") {
    return <PdfModalViewer url={url} filename={filename} />;
  } else if (
    [".doc", ".docx", ".xls", ".xlsx", ".ppt", ".pptx", ".pdf"].includes(type)
  ) {
    return <OfficeViewer url={url} />;
  } else if (type === ".csv") {
    return <TextViewer url={url}></TextViewer>;
  } else if (type === ".txt") {
    return <TextViewer url={url} />;
  } else if ([".mp4", "mp4", ".mov"].includes(type)) {
    return <VideoViewer url={url} />;
  }else if (type === "link") {
    return <NoLink attachment={attachment} />;
  } else if (type === ".mp3") {
    return <AudioViewer url={url} />;
  } else {
    return (
      <div className="h-100 w-100 d-flex justify-content-center align-items-center">
        <div className="spinner-border" role="status"></div>
      </div>
    );
  }
};
